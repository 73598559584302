import { cn } from "@/lib/cn";

export function LoadingDots({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) {
	return (
		<span className={cn("flex h-5 items-center gap-[2px]", className)} {...props}>
			<span className="size-1 animate-loading-pulse-1 rounded-full bg-current opacity-50" />
			<span className="size-1 animate-loading-pulse-2 rounded-full bg-current opacity-50" />
			<span className="size-1 animate-loading-pulse-3 rounded-full bg-current opacity-50" />
		</span>
	);
}
