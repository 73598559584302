import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";

import { cn } from "@/lib/cn";
import { LoadingDots } from "./loading-dots";
const buttonVariants = cva(
	"inline-flex items-center justify-center rounded-lg px-4 py-2 text-base font-medium transition-all ring-offset-background min-h-7 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 aria-disabled:opacity-50 aria-disabled:cursor-not-allowed",
	{
		variants: {
			variant: {
				primary: "bg-primary text-primary-foreground hover:bg-primary/80",
				bright: "bg-gray-600 text-black border border-gray-600 hover:bg-gray-700 hover:border-gray-700",
				// (not part of design system) destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/80",
				outline: "border border-primary hover:bg-gray-600",
				nude: "bg-gray-600 aria-disabled:bg-gray-600",
				link: "underline-offset-[2.5px] underline text-primary p-0",
			},
		},
		defaultVariants: {
			variant: "primary",
		},
	}
);

export type ButtonProps = {
	asChild?: boolean;
	loading?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "disabled"> &
	VariantProps<typeof buttonVariants>;

/**
 * KS button component.
 *
 * @example
 * <Button variant="primary" state="disabled" onClick={handleClick}>
 *   Click me
 * </Button>
 *
 * @remarks
 * The `disabled` prop can't be used directly in this component because it can affect accessibility of the button. Instead, use the `aria-disabled` prop.
 * See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-disabled for more information.
 *
 * @param {boolean} [asChild=false] - Determines whether the button should be rendered as a child component.
 * @params All other props are passed to the button element.
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, asChild = false, children, loading, onClick, ...props }, ref) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(buttonVariants({ variant, className }), loading && "cursor-wait")}
				ref={ref}
				{...props}
				onClick={
					props["aria-disabled"] ?? loading
						? (e) => {
								e.preventDefault();
								return false;
							}
						: onClick
				}
			>
				<LoadingDots
					className={cn(
						"pointer-events-none absolute opacity-0 transition-opacity duration-300",
						loading && "opacity-100"
					)}
				/>
				<span className={cn("transition-opacity duration-300", loading && "opacity-0")}>{children}</span>
			</Comp>
		);
	}
);
Button.displayName = "Button";

export { Button, buttonVariants };
