/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	/** An ISO 8601-encoded date */
	ISO8601Date: { input: string; output: string };
	/** An ISO 8601-encoded datetime */
	ISO8601DateTime: { input: string; output: string };
	/** Represents untyped JSON */
	JSON: { input: Record<string, unknown>; output: Record<string, unknown> };
};

/** Autogenerated input type of AcceptResaleOffer */
export type AcceptResaleOfferInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	code: Scalars["String"]["input"];
};

/** Autogenerated return type of AcceptResaleOffer. */
export type AcceptResaleOfferPayload = {
	cart: Cart;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of AddItems */
export type AddItemsInput = {
	bundle?: InputMaybe<Scalars["Boolean"]["input"]>;
	bundleWith?: InputMaybe<Array<Scalars["ID"]["input"]>>;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	requests: Array<Request>;
};

/** Autogenerated return type of AddItems. */
export type AddItemsPayload = {
	cart: Cart;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

export type Address = {
	address: Maybe<Scalars["String"]["output"]>;
	address2: Maybe<Scalars["String"]["output"]>;
	city: Maybe<Scalars["String"]["output"]>;
	company: Maybe<Scalars["String"]["output"]>;
	country: Maybe<Country>;
	countryCode: Maybe<Scalars["String"]["output"]>;
	firstName: Maybe<Scalars["String"]["output"]>;
	lastName: Maybe<Scalars["String"]["output"]>;
	postCode: Maybe<Scalars["String"]["output"]>;
};

export type AddressArgument = {
	address: Scalars["String"]["input"];
	address2?: InputMaybe<Scalars["String"]["input"]>;
	city: Scalars["String"]["input"];
	company?: InputMaybe<Scalars["String"]["input"]>;
	countryCode: Scalars["String"]["input"];
	firstName: Scalars["String"]["input"];
	lastName: Scalars["String"]["input"];
	postCode: Scalars["String"]["input"];
};

export type Artist = CategoryInterface & {
	abstract: Maybe<Scalars["String"]["output"]>;
	alternativeNames: Array<Scalars["String"]["output"]>;
	backgroundImage: Maybe<Image>;
	description: Maybe<Scalars["String"]["output"]>;
	events: EventConnection;
	featuredAt: Maybe<Scalars["String"]["output"]>;
	headerImage: Maybe<Image>;
	id: Scalars["ID"]["output"];
	landscapeImage: Maybe<Image>;
	mainCategory: Scalars["Boolean"]["output"];
	merchProducts: MerchProductConnection;
	name: Scalars["String"]["output"];
	order: Scalars["Int"]["output"];
	parentCategory: CategoryInterface;
	parentCategoryId: Scalars["ID"]["output"];
	portraitImage: Maybe<Image>;
	shopId: Scalars["ID"]["output"];
	sliderImage: Maybe<Image>;
	slug: Scalars["String"]["output"];
	sortOrderHomepage: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketBottom: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopLeft: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopRight: Maybe<Scalars["Int"]["output"]>;
	squareImage: Maybe<Image>;
	subcategories: CategoryInterfaceConnection;
	subtitle: Maybe<Scalars["String"]["output"]>;
	subtitleInShop: Scalars["Boolean"]["output"];
	ticketImage: Maybe<Image>;
	tours: TourConnection;
	type: CategoryType;
};

export type ArtistEventsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type ArtistMerchProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortBy?: InputMaybe<CategoryProductSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type ArtistSubcategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ArtistToursArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Artist. */
export type ArtistConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<ArtistEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Artist>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ArtistEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Artist>;
};

export type BankAccountInput = {
	bankName?: InputMaybe<Scalars["String"]["input"]>;
	bic?: InputMaybe<Scalars["String"]["input"]>;
	iban: Scalars["String"]["input"];
	name: Scalars["String"]["input"];
};

export type Brand = CategoryInterface & {
	abstract: Maybe<Scalars["String"]["output"]>;
	alternativeNames: Array<Scalars["String"]["output"]>;
	description: Maybe<Scalars["String"]["output"]>;
	events: EventConnection;
	featuredAt: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	landscapeImage: Maybe<Image>;
	mainCategory: Scalars["Boolean"]["output"];
	merchProducts: MerchProductConnection;
	name: Scalars["String"]["output"];
	order: Scalars["Int"]["output"];
	parentCategory: CategoryInterface;
	parentCategoryId: Scalars["ID"]["output"];
	portraitImage: Maybe<Image>;
	shopId: Scalars["ID"]["output"];
	slug: Scalars["String"]["output"];
	sortOrderHomepage: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketBottom: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopLeft: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopRight: Maybe<Scalars["Int"]["output"]>;
	squareImage: Maybe<Image>;
	subcategories: CategoryInterfaceConnection;
	subtitle: Maybe<Scalars["String"]["output"]>;
	subtitleInShop: Scalars["Boolean"]["output"];
	type: CategoryType;
};

export type BrandEventsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type BrandMerchProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortBy?: InputMaybe<CategoryProductSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type BrandSubcategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BundleProduct = ProductInterface & {
	artist: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use artists instead */
	artistCategory: Maybe<Artist>;
	artists: ArtistConnection;
	available: Scalars["Boolean"]["output"];
	cartLimit: Scalars["Int"]["output"];
	categories: CategoryInterfaceConnection;
	combinedProducts: Maybe<Array<Array<ProductInterface>>>;
	description: Maybe<Scalars["String"]["output"]>;
	descriptiveName: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	kulturpassUrl: Maybe<Scalars["String"]["output"]>;
	meta: Scalars["JSON"]["output"];
	name: Maybe<Scalars["String"]["output"]>;
	overlayDetailImage: Maybe<Image>;
	overlayListingImage: Maybe<Image>;
	owningCompanyAddress: Maybe<Address>;
	personalizationRequirement: PersonalizationRequirement;
	presaleAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	price: Maybe<Scalars["Float"]["output"]>;
	productVariant: Maybe<ProductVariantInterface>;
	productVariants: ProductVariantInterfaceConnection;
	publicShippableFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	resaleOffers: ResaleOfferConnection;
	resaleOffersAvailable: Scalars["Boolean"]["output"];
	resaleSetting: ResaleSetting;
	shippingRequirements: Array<ShippingRequirement>;
	sku: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
};

export type BundleProductArtistsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BundleProductCategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BundleProductPriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type BundleProductProductVariantArgs = {
	id: Scalars["ID"]["input"];
};

export type BundleProductProductVariantsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BundleProductResaleOffersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Autogenerated input type of CancelResale */
export type CancelResaleInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	itemIds: Array<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CancelResale. */
export type CancelResalePayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	items: Array<CartItem>;
};

export type Cart = {
	checkoutSubtotal: Scalars["Float"]["output"];
	countryCode: Scalars["ID"]["output"];
	id: Scalars["ID"]["output"];
	items: Array<CartItem>;
	mergeable: Scalars["Boolean"]["output"];
	reference: Maybe<Scalars["String"]["output"]>;
	total: Scalars["Float"]["output"];
};

export type CartItem = {
	bundledItemIds: Array<Scalars["ID"]["output"]>;
	buyerFee: Scalars["Float"]["output"];
	id: Scalars["ID"]["output"];
	image: Maybe<Image>;
	invalidated: Scalars["Boolean"]["output"];
	payment: Maybe<Payment>;
	paymentId: Maybe<Scalars["ID"]["output"]>;
	personalIdentifier: Maybe<Scalars["String"]["output"]>;
	product: ProductInterface;
	productId: Scalars["ID"]["output"];
	productVariant: ProductVariantInterface;
	productVariantId: Scalars["ID"]["output"];
	resaleAvailable: Scalars["Boolean"]["output"];
	resaleFee: Scalars["Float"]["output"];
	resaleOffer: Maybe<ResaleOffer>;
	resoldByOffer: Maybe<ResaleOffer>;
	seat: Maybe<Seat>;
	seatId: Maybe<Scalars["ID"]["output"]>;
	section: Maybe<VenueSection>;
	sectionId: Maybe<Scalars["ID"]["output"]>;
	ticketUrl: Maybe<Scalars["String"]["output"]>;
	total: Scalars["Float"]["output"];
	validationCode: Maybe<Scalars["String"]["output"]>;
};

export type Category = CategoryInterface & {
	abstract: Maybe<Scalars["String"]["output"]>;
	alternativeNames: Array<Scalars["String"]["output"]>;
	description: Maybe<Scalars["String"]["output"]>;
	events: EventConnection;
	featuredAt: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	landscapeImage: Maybe<Image>;
	mainCategory: Scalars["Boolean"]["output"];
	merchProducts: MerchProductConnection;
	name: Scalars["String"]["output"];
	order: Scalars["Int"]["output"];
	parentCategory: CategoryInterface;
	parentCategoryId: Scalars["ID"]["output"];
	portraitImage: Maybe<Image>;
	shopId: Scalars["ID"]["output"];
	slug: Scalars["String"]["output"];
	sortOrderHomepage: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketBottom: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopLeft: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopRight: Maybe<Scalars["Int"]["output"]>;
	squareImage: Maybe<Image>;
	subcategories: CategoryInterfaceConnection;
	subtitle: Maybe<Scalars["String"]["output"]>;
	subtitleInShop: Scalars["Boolean"]["output"];
	type: CategoryType;
};

export type CategoryEventsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type CategoryMerchProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortBy?: InputMaybe<CategoryProductSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type CategorySubcategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CategoryInterface = {
	abstract: Maybe<Scalars["String"]["output"]>;
	alternativeNames: Array<Scalars["String"]["output"]>;
	description: Maybe<Scalars["String"]["output"]>;
	events: EventConnection;
	featuredAt: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	landscapeImage: Maybe<Image>;
	mainCategory: Scalars["Boolean"]["output"];
	merchProducts: MerchProductConnection;
	name: Scalars["String"]["output"];
	order: Scalars["Int"]["output"];
	parentCategory: CategoryInterface;
	parentCategoryId: Scalars["ID"]["output"];
	portraitImage: Maybe<Image>;
	shopId: Scalars["ID"]["output"];
	slug: Scalars["String"]["output"];
	sortOrderHomepage: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketBottom: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopLeft: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopRight: Maybe<Scalars["Int"]["output"]>;
	squareImage: Maybe<Image>;
	subcategories: CategoryInterfaceConnection;
	subtitle: Maybe<Scalars["String"]["output"]>;
	subtitleInShop: Scalars["Boolean"]["output"];
	type: CategoryType;
};

export type CategoryInterfaceEventsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type CategoryInterfaceMerchProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortBy?: InputMaybe<CategoryProductSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type CategoryInterfaceSubcategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for CategoryInterface. */
export type CategoryInterfaceConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<CategoryInterfaceEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<CategoryInterface>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CategoryInterfaceEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<CategoryInterface>;
};

export type CategoryProductSortBy =
	/** First by order in its category, then by published date (descending). */
	| "ORDER"
	/** By published date (beginning of period). */
	| "PUBLISHED_FROM";

export type CategoryType = "ARTIST" | "BRAND" | "CATEGORY" | "LABEL" | "TOUR";

/** Autogenerated input type of ChangeCountry */
export type ChangeCountryInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	countryCode: Scalars["ID"]["input"];
};

/** Autogenerated return type of ChangeCountry. */
export type ChangeCountryPayload = {
	cart: Cart;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of ConfirmOrderPayment */
export type ConfirmOrderPaymentInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	meta?: InputMaybe<Scalars["JSON"]["input"]>;
	orderId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ConfirmOrderPayment. */
export type ConfirmOrderPaymentPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

export type Country = {
	countryCode: Scalars["String"]["output"];
	eu: Scalars["Boolean"]["output"];
	name: Scalars["String"]["output"];
};

/** The connection type for Country. */
export type CountryConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<CountryEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Country>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CountryEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Country>;
};

/** Autogenerated input type of CreateStripeIntentPayment */
export type CreateStripeIntentPaymentInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	orderId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateStripeIntentPayment. */
export type CreateStripeIntentPaymentPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	externalPaymentId: Scalars["String"]["output"];
	externalPaymentToken: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
};

/** Autogenerated input type of CreateStripePayment */
export type CreateStripePaymentInput = {
	cancelUrl: Scalars["String"]["input"];
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	orderId: Scalars["ID"]["input"];
	successUrl: Scalars["String"]["input"];
};

/** Autogenerated return type of CreateStripePayment. */
export type CreateStripePaymentPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	externalPaymentId: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
};

export type Delivery = {
	address: Address;
	id: Scalars["ID"]["output"];
	reference: Scalars["String"]["output"];
	status: DeliveryStatus;
	trackingCode: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for Delivery. */
export type DeliveryConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<DeliveryEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Delivery>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DeliveryEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Delivery>;
};

export type DeliveryStatus =
	/** Canceled */
	| "canceled"
	/** Draft */
	| "draft"
	/** Fulfillment */
	| "fulfillment"
	/** Pending */
	| "pending"
	/** Sent */
	| "sent";

export type DiscountProduct = ProductInterface & {
	artist: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use artists instead */
	artistCategory: Maybe<Artist>;
	artists: ArtistConnection;
	available: Scalars["Boolean"]["output"];
	cartLimit: Scalars["Int"]["output"];
	categories: CategoryInterfaceConnection;
	combinedProducts: Maybe<Array<Array<ProductInterface>>>;
	description: Maybe<Scalars["String"]["output"]>;
	descriptiveName: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	kulturpassUrl: Maybe<Scalars["String"]["output"]>;
	meta: Scalars["JSON"]["output"];
	name: Maybe<Scalars["String"]["output"]>;
	overlayDetailImage: Maybe<Image>;
	overlayListingImage: Maybe<Image>;
	owningCompanyAddress: Maybe<Address>;
	personalizationRequirement: PersonalizationRequirement;
	presaleAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	price: Maybe<Scalars["Float"]["output"]>;
	productVariant: Maybe<ProductVariantInterface>;
	productVariants: ProductVariantInterfaceConnection;
	publicShippableFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	resaleOffers: ResaleOfferConnection;
	resaleOffersAvailable: Scalars["Boolean"]["output"];
	resaleSetting: ResaleSetting;
	shippingRequirements: Array<ShippingRequirement>;
	sku: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
};

export type DiscountProductArtistsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DiscountProductCategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DiscountProductPriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type DiscountProductProductVariantArgs = {
	id: Scalars["ID"]["input"];
};

export type DiscountProductProductVariantsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DiscountProductResaleOffersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Event = ProductInterface & {
	artist: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use artists instead */
	artistCategory: Maybe<Artist>;
	artists: ArtistConnection;
	available: Scalars["Boolean"]["output"];
	availableSeatIds: Array<Scalars["ID"]["output"]>;
	cartLimit: Scalars["Int"]["output"];
	categories: CategoryInterfaceConnection;
	checkInEndAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	checkInStartAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	combinedProducts: Maybe<Array<Array<ProductInterface>>>;
	description: Maybe<Scalars["String"]["output"]>;
	descriptiveName: Scalars["String"]["output"];
	displayMap: Scalars["Boolean"]["output"];
	doorTime: Maybe<Scalars["String"]["output"]>;
	happeningFrom: Scalars["ISO8601DateTime"]["output"];
	happeningUntil: Scalars["ISO8601DateTime"]["output"];
	id: Scalars["ID"]["output"];
	image: Maybe<Image>;
	kulturpassUrl: Maybe<Scalars["String"]["output"]>;
	localOrganizer: Maybe<Scalars["String"]["output"]>;
	meta: Scalars["JSON"]["output"];
	name: Maybe<Scalars["String"]["output"]>;
	overlayDetailImage: Maybe<Image>;
	overlayListingImage: Maybe<Image>;
	owningCompanyAddress: Maybe<Address>;
	personalizationRequirement: PersonalizationRequirement;
	presaleAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	price: Maybe<Scalars["Float"]["output"]>;
	productVariant: Maybe<ProductVariantInterface>;
	productVariants: ProductVariantInterfaceConnection;
	publicShippableFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	resaleOffers: ResaleOfferConnection;
	resaleOffersAvailable: Scalars["Boolean"]["output"];
	resaleSetting: ResaleSetting;
	/** URL to get the seatmap as a JSON object, if the venue layout requires map selection. */
	seatmapUrl: Maybe<Scalars["String"]["output"]>;
	shippingRequirements: Array<ShippingRequirement>;
	sku: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
	soldOutMessage: Maybe<Scalars["String"]["output"]>;
	stageTime: Maybe<Scalars["String"]["output"]>;
	tourOrganizer: Maybe<Scalars["String"]["output"]>;
	tours: TourConnection;
	venue: Venue;
};

export type EventArtistsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EventCategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EventPriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventProductVariantArgs = {
	id: Scalars["ID"]["input"];
};

export type EventProductVariantsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EventResaleOffersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EventToursArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Event. */
export type EventConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<EventEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Event>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EventEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Event>;
};

export type EventProductVariant = ProductVariantInterface & {
	active: Scalars["Boolean"]["output"];
	activeFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	activeUntil: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	available: Scalars["Boolean"]["output"];
	availableSectionIds: Array<Scalars["ID"]["output"]>;
	availableSections: VenueSectionConnection;
	description: Maybe<Scalars["String"]["output"]>;
	ean: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	order: Scalars["Int"]["output"];
	physical: Scalars["Boolean"]["output"];
	price: Scalars["Float"]["output"];
	pricingCategory: Maybe<PricingCategory>;
	product: ProductInterface;
	productId: Scalars["ID"]["output"];
	sku: Scalars["String"]["output"];
	weight: Maybe<Scalars["Float"]["output"]>;
};

export type EventProductVariantAvailableSectionsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EventProductVariantPriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated input type of FinishCodeLogin */
export type FinishCodeLoginInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	code: Scalars["String"]["input"];
	email: Scalars["String"]["input"];
};

/** Autogenerated return type of FinishCodeLogin. */
export type FinishCodeLoginPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	session: Session;
};

/** Autogenerated input type of FinishLogin */
export type FinishLoginInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	token: Scalars["String"]["input"];
};

/** Autogenerated return type of FinishLogin. */
export type FinishLoginPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	session: Session;
};

/** Autogenerated input type of Heartbeat */
export type HeartbeatInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of Heartbeat. */
export type HeartbeatPayload = {
	cart: Maybe<Cart>;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	session: Session;
};

export type Image = {
	alt: Maybe<Scalars["String"]["output"]>;
	height: Maybe<Scalars["Int"]["output"]>;
	id: Scalars["ID"]["output"];
	order: Scalars["Int"]["output"];
	tags: Array<Scalars["String"]["output"]>;
	thumbhash: Maybe<Scalars["String"]["output"]>;
	thumbnailAt10: Scalars["String"]["output"];
	thumbnailAt75: Scalars["String"]["output"];
	thumbnailAt100: Scalars["String"]["output"];
	thumbnailAt150: Scalars["String"]["output"];
	thumbnailAt180: Scalars["String"]["output"];
	thumbnailAt240: Scalars["String"]["output"];
	thumbnailAt360: Scalars["String"]["output"];
	thumbnailAt370: Scalars["String"]["output"];
	thumbnailAt500: Scalars["String"]["output"];
	thumbnailAt740: Scalars["String"]["output"];
	thumbnailAt1000: Scalars["String"]["output"];
	thumbnailAt1500: Scalars["String"]["output"];
	url: Scalars["String"]["output"];
	width: Maybe<Scalars["Int"]["output"]>;
};

export type ImageUrlArgs = {
	width: Scalars["Int"]["input"];
};

/** The connection type for Image. */
export type ImageConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<ImageEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Image>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ImageEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Image>;
};

export type Invoice = {
	address: Address;
	id: Scalars["ID"]["output"];
	paidAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	reference: Scalars["String"]["output"];
	status: InvoiceStatus;
	total: Scalars["Float"]["output"];
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<InvoiceEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Invoice>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InvoiceEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Invoice>;
};

export type InvoiceStatus =
	/** Booked */
	| "booked"
	/** Canceled */
	| "canceled"
	/** Draft */
	| "draft"
	/** Pending */
	| "pending";

export type Label = CategoryInterface & {
	abstract: Maybe<Scalars["String"]["output"]>;
	alternativeNames: Array<Scalars["String"]["output"]>;
	description: Maybe<Scalars["String"]["output"]>;
	events: EventConnection;
	featuredAt: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	landscapeImage: Maybe<Image>;
	mainCategory: Scalars["Boolean"]["output"];
	merchProducts: MerchProductConnection;
	name: Scalars["String"]["output"];
	order: Scalars["Int"]["output"];
	parentCategory: CategoryInterface;
	parentCategoryId: Scalars["ID"]["output"];
	portraitImage: Maybe<Image>;
	shopId: Scalars["ID"]["output"];
	slug: Scalars["String"]["output"];
	sortOrderHomepage: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketBottom: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopLeft: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopRight: Maybe<Scalars["Int"]["output"]>;
	squareImage: Maybe<Image>;
	subcategories: CategoryInterfaceConnection;
	subtitle: Maybe<Scalars["String"]["output"]>;
	subtitleInShop: Scalars["Boolean"]["output"];
	type: CategoryType;
};

export type LabelEventsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type LabelMerchProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortBy?: InputMaybe<CategoryProductSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type LabelSubcategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Autogenerated input type of LogActivity */
export type LogActivityInput = {
	action: Scalars["String"]["input"];
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	details: Scalars["JSON"]["input"];
};

/** Autogenerated return type of LogActivity. */
export type LogActivityPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

export type MerchProduct = ProductInterface & {
	additionalInformation: Maybe<ProductDetail>;
	artist: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use artists instead */
	artistCategory: Maybe<Artist>;
	artists: ArtistConnection;
	available: Scalars["Boolean"]["output"];
	cartLimit: Scalars["Int"]["output"];
	categories: CategoryInterfaceConnection;
	color: Maybe<Scalars["String"]["output"]>;
	combinedProducts: Maybe<Array<Array<ProductInterface>>>;
	description: Maybe<Scalars["String"]["output"]>;
	descriptiveName: Scalars["String"]["output"];
	details: Maybe<ProductDetail>;
	id: Scalars["ID"]["output"];
	images: ImageConnection;
	kulturpassUrl: Maybe<Scalars["String"]["output"]>;
	lowStock: Scalars["Boolean"]["output"];
	material: Maybe<Scalars["String"]["output"]>;
	meta: Scalars["JSON"]["output"];
	name: Maybe<Scalars["String"]["output"]>;
	originalPrice: Maybe<Scalars["Float"]["output"]>;
	overlayDetailImage: Maybe<Image>;
	overlayListingImage: Maybe<Image>;
	owningCompanyAddress: Maybe<Address>;
	personalizationRequirement: PersonalizationRequirement;
	pictogram: Maybe<Image>;
	presaleAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	price: Maybe<Scalars["Float"]["output"]>;
	productType: Maybe<Scalars["String"]["output"]>;
	productVariant: Maybe<ProductVariantInterface>;
	productVariants: ProductVariantInterfaceConnection;
	publicShippableFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	resaleOffers: ResaleOfferConnection;
	resaleOffersAvailable: Scalars["Boolean"]["output"];
	resaleSetting: ResaleSetting;
	shippingRequirements: Array<ShippingRequirement>;
	sku: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
};

export type MerchProductArtistsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MerchProductCategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MerchProductImagesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MerchProductOriginalPriceArgs = {
	countryCode: Scalars["String"]["input"];
};

export type MerchProductPriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type MerchProductProductVariantArgs = {
	id: Scalars["ID"]["input"];
};

export type MerchProductProductVariantsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MerchProductResaleOffersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for MerchProduct. */
export type MerchProductConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<MerchProductEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<MerchProduct>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MerchProductEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<MerchProduct>;
};

/** Autogenerated input type of MergePlaceOrder */
export type MergePlaceOrderInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	email: Scalars["String"]["input"];
	orderReference: Scalars["String"]["input"];
};

/** Autogenerated return type of MergePlaceOrder. */
export type MergePlaceOrderPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	order: Order;
};

export type Mutation = {
	acceptResaleOffer: Maybe<AcceptResaleOfferPayload>;
	addItems: Maybe<AddItemsPayload>;
	cancelResale: Maybe<CancelResalePayload>;
	changeCountry: Maybe<ChangeCountryPayload>;
	confirmOrderPayment: Maybe<ConfirmOrderPaymentPayload>;
	createStripeIntentPayment: Maybe<CreateStripeIntentPaymentPayload>;
	createStripePayment: Maybe<CreateStripePaymentPayload>;
	finishCodeLogin: Maybe<FinishCodeLoginPayload>;
	finishLogin: Maybe<FinishLoginPayload>;
	heartbeat: Maybe<HeartbeatPayload>;
	logActivity: Maybe<LogActivityPayload>;
	mergePlaceOrder: Maybe<MergePlaceOrderPayload>;
	offerForResale: Maybe<OfferForResalePayload>;
	placeOrder: Maybe<PlaceOrderPayload>;
	removeItem: Maybe<RemoveItemPayload>;
	requestCodeLogin: Maybe<RequestCodeLoginPayload>;
	requestEmailLogin: Maybe<RequestEmailLoginPayload>;
	requestItemPersonalizationChange: Maybe<RequestItemPersonalizationChangePayload>;
	resumeSession: Maybe<ResumeSessionPayload>;
	submitRefundRequest: Maybe<SubmitRefundRequestPayload>;
	updatePersonalIdentifier: Maybe<UpdatePersonalIdentifierPayload>;
	updatePreferredPaymentMethod: Maybe<UpdatePreferredPaymentMethodPayload>;
};

export type MutationAcceptResaleOfferArgs = {
	input: AcceptResaleOfferInput;
};

export type MutationAddItemsArgs = {
	input: AddItemsInput;
};

export type MutationCancelResaleArgs = {
	input: CancelResaleInput;
};

export type MutationChangeCountryArgs = {
	input: ChangeCountryInput;
};

export type MutationConfirmOrderPaymentArgs = {
	input: ConfirmOrderPaymentInput;
};

export type MutationCreateStripeIntentPaymentArgs = {
	input: CreateStripeIntentPaymentInput;
};

export type MutationCreateStripePaymentArgs = {
	input: CreateStripePaymentInput;
};

export type MutationFinishCodeLoginArgs = {
	input: FinishCodeLoginInput;
};

export type MutationFinishLoginArgs = {
	input: FinishLoginInput;
};

export type MutationHeartbeatArgs = {
	input: HeartbeatInput;
};

export type MutationLogActivityArgs = {
	input: LogActivityInput;
};

export type MutationMergePlaceOrderArgs = {
	input: MergePlaceOrderInput;
};

export type MutationOfferForResaleArgs = {
	input: OfferForResaleInput;
};

export type MutationPlaceOrderArgs = {
	input: PlaceOrderInput;
};

export type MutationRemoveItemArgs = {
	input: RemoveItemInput;
};

export type MutationRequestCodeLoginArgs = {
	input: RequestCodeLoginInput;
};

export type MutationRequestEmailLoginArgs = {
	input: RequestEmailLoginInput;
};

export type MutationRequestItemPersonalizationChangeArgs = {
	input: RequestItemPersonalizationChangeInput;
};

export type MutationResumeSessionArgs = {
	input: ResumeSessionInput;
};

export type MutationSubmitRefundRequestArgs = {
	input: SubmitRefundRequestInput;
};

export type MutationUpdatePersonalIdentifierArgs = {
	input: UpdatePersonalIdentifierInput;
};

export type MutationUpdatePreferredPaymentMethodArgs = {
	input: UpdatePreferredPaymentMethodInput;
};

export type News = {
	category: Maybe<Scalars["String"]["output"]>;
	createdAt: Scalars["ISO8601DateTime"]["output"];
	id: Scalars["ID"]["output"];
	image: Maybe<Image>;
	inverse: Maybe<Scalars["Boolean"]["output"]>;
	text: Maybe<Scalars["String"]["output"]>;
	title: Maybe<Scalars["String"]["output"]>;
	url: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for News. */
export type NewsConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<NewsEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<News>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NewsEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<News>;
};

/** Autogenerated input type of OfferForResale */
export type OfferForResaleInput = {
	bankAccount?: InputMaybe<BankAccountInput>;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	itemIds: Array<Scalars["String"]["input"]>;
	public: Scalars["Boolean"]["input"];
};

/** Autogenerated return type of OfferForResale. */
export type OfferForResalePayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	items: Array<CartItem>;
};

export type Order = {
	balance: Scalars["Float"]["output"];
	cancelAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	checkoutSubtotal: Scalars["Float"]["output"];
	countryCode: Scalars["ID"]["output"];
	deliveries: DeliveryConnection;
	id: Scalars["ID"]["output"];
	invoices: InvoiceConnection;
	items: Array<CartItem>;
	mergeable: Scalars["Boolean"]["output"];
	orderedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	paymentLink: Scalars["String"]["output"];
	payments: PaymentConnection;
	preferredPaymentMethod: Maybe<PaymentMethod>;
	reference: Scalars["String"]["output"];
	shop: Maybe<ShopInfo>;
	status: OrderStatus;
	total: Scalars["Float"]["output"];
	totalIncludingShipping: Scalars["Float"]["output"];
};

export type OrderDeliveriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrderInvoicesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrderPaymentsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Order. */
export type OrderConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<OrderEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Order>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Order>;
};

export type OrderStatus =
	/** Canceled */
	| "canceled"
	/** Cart */
	| "cart"
	/** Disputed */
	| "disputed"
	/** Done */
	| "done"
	/** Draft */
	| "draft"
	/** Fulfillment */
	| "fulfillment"
	/** Incomplete */
	| "incomplete"
	/** Payment pending */
	| "payment_pending"
	/** Pending fulfillment */
	| "pending_fulfillment"
	/** Problem */
	| "problem"
	/** Processing */
	| "processing"
	/** Refund pending */
	| "refund_pending";

/** Information about pagination in a connection. */
export type PageInfo = {
	/** When paginating forwards, the cursor to continue. */
	endCursor: Maybe<Scalars["String"]["output"]>;
	/** When paginating forwards, are there more items? */
	hasNextPage: Scalars["Boolean"]["output"];
	/** When paginating backwards, are there more items? */
	hasPreviousPage: Scalars["Boolean"]["output"];
	/** When paginating backwards, the cursor to continue. */
	startCursor: Maybe<Scalars["String"]["output"]>;
};

export type Payment = {
	amount: Scalars["Float"]["output"];
	id: Scalars["ID"]["output"];
	paidAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	paymentMethod: PaymentMethod;
	reference: Scalars["String"]["output"];
	status: PaymentStatus;
};

/** The connection type for Payment. */
export type PaymentConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<PaymentEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Payment>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Payment>;
};

export type PaymentMethod = {
	description: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
};

/** The connection type for PaymentMethod. */
export type PaymentMethodConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<PaymentMethodEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<PaymentMethod>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentMethodEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<PaymentMethod>;
};

export type PaymentStatus =
	/** Canceled */
	| "canceled"
	/** Failed */
	| "failed"
	/** Paid */
	| "paid"
	/** Pending */
	| "pending";

export type PersonalizationRequirement =
	/** Contact tracing is required. Ask for a full address and contact (e.g. email or phone) and serialize it to the identifier field. Also a check in before the event is required to enable the ticket. */
	| "check_in_required"
	/** Contact tracing is required. Ask for a full address and contact (e.g. email or phone) and serialize it to the identifier field. */
	| "contact_tracing_required"
	/** The whole group of tickets are personalized to the same name. Ask for one identifier and set it to all tickets */
	| "group_personalization_required"
	/** There is no personalization required. */
	| "no_personalization_required"
	/** Every ticket is personalized individually. Ask for the name of each ticket's owner. */
	| "ticket_personalization_required";

/** Autogenerated input type of PlaceOrder */
export type PlaceOrderInput = {
	billingAddress: AddressArgument;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	email: Scalars["String"]["input"];
	paymentMethod: Scalars["ID"]["input"];
	shippingAddress?: InputMaybe<AddressArgument>;
	shippingMethod?: InputMaybe<Scalars["ID"]["input"]>;
	/** Tags to add to the order. */
	tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** Autogenerated return type of PlaceOrder. */
export type PlaceOrderPayload = {
	cart: Cart;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

export type PricingCategory = {
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
};

export type ProductDetail = {
	description: Maybe<Scalars["String"]["output"]>;
	title: Maybe<Scalars["String"]["output"]>;
};

export type ProductInterface = {
	artist: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use artists instead */
	artistCategory: Maybe<Artist>;
	artists: ArtistConnection;
	available: Scalars["Boolean"]["output"];
	cartLimit: Scalars["Int"]["output"];
	categories: CategoryInterfaceConnection;
	combinedProducts: Maybe<Array<Array<ProductInterface>>>;
	description: Maybe<Scalars["String"]["output"]>;
	descriptiveName: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	kulturpassUrl: Maybe<Scalars["String"]["output"]>;
	meta: Scalars["JSON"]["output"];
	name: Maybe<Scalars["String"]["output"]>;
	overlayDetailImage: Maybe<Image>;
	overlayListingImage: Maybe<Image>;
	owningCompanyAddress: Maybe<Address>;
	personalizationRequirement: PersonalizationRequirement;
	presaleAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	price: Maybe<Scalars["Float"]["output"]>;
	productVariant: Maybe<ProductVariantInterface>;
	productVariants: ProductVariantInterfaceConnection;
	publicShippableFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	resaleOffers: ResaleOfferConnection;
	resaleOffersAvailable: Scalars["Boolean"]["output"];
	resaleSetting: ResaleSetting;
	shippingRequirements: Array<ShippingRequirement>;
	sku: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
};

export type ProductInterfaceArtistsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProductInterfaceCategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProductInterfacePriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductInterfaceProductVariantArgs = {
	id: Scalars["ID"]["input"];
};

export type ProductInterfaceProductVariantsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProductInterfaceResaleOffersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for ProductInterface. */
export type ProductInterfaceConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<ProductInterfaceEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<ProductInterface>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductInterfaceEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<ProductInterface>;
};

export type ProductVariant = ProductVariantInterface & {
	active: Scalars["Boolean"]["output"];
	activeFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	activeUntil: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	available: Scalars["Boolean"]["output"];
	description: Maybe<Scalars["String"]["output"]>;
	ean: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	order: Scalars["Int"]["output"];
	overlayDetailImage: Maybe<Image>;
	overlayListingImage: Maybe<Image>;
	physical: Scalars["Boolean"]["output"];
	price: Scalars["Float"]["output"];
	product: ProductInterface;
	productId: Scalars["ID"]["output"];
	sku: Scalars["String"]["output"];
	weight: Maybe<Scalars["Float"]["output"]>;
};

export type ProductVariantPriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductVariantInterface = {
	active: Scalars["Boolean"]["output"];
	activeFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	activeUntil: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	available: Scalars["Boolean"]["output"];
	description: Maybe<Scalars["String"]["output"]>;
	ean: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	order: Scalars["Int"]["output"];
	physical: Scalars["Boolean"]["output"];
	price: Scalars["Float"]["output"];
	product: ProductInterface;
	productId: Scalars["ID"]["output"];
	sku: Scalars["String"]["output"];
	weight: Maybe<Scalars["Float"]["output"]>;
};

export type ProductVariantInterfacePriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for ProductVariantInterface. */
export type ProductVariantInterfaceConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<ProductVariantInterfaceEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<ProductVariantInterface>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductVariantInterfaceEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<ProductVariantInterface>;
};

export type Query = {
	cart: Maybe<Cart>;
	categories: CategoryInterfaceConnection;
	category: CategoryInterface;
	countries: CountryConnection;
	event: Event;
	events: EventConnection;
	merchProduct: MerchProduct;
	merchProducts: MerchProductConnection;
	news: NewsConnection;
	order: Order;
	orders: OrderConnection;
	paymentMethods: PaymentMethodConnection;
	/** Find a product (event, merch, whatever its type) by its ID. */
	product: ProductInterface;
	products: ProductInterfaceConnection;
	refundRequest: RefundRequest;
	resaleOffer: ResaleOffer;
	search: SearchResult;
	session: Session;
	/** Needs to have both product variant IDs and a country code to return a value */
	shippingMethods: ShippingMethodConnection;
	shop: ShopInfo;
	ticketDetails: Maybe<TicketInfo>;
};

export type QueryCategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	mainCategory?: InputMaybe<Scalars["Boolean"]["input"]>;
	sortOrderHomepage?: InputMaybe<Scalars["Boolean"]["input"]>;
	sortOrderTicketBottom?: InputMaybe<Scalars["Boolean"]["input"]>;
	sortOrderTicketTopLeft?: InputMaybe<Scalars["Boolean"]["input"]>;
	sortOrderTicketTopRight?: InputMaybe<Scalars["Boolean"]["input"]>;
	types?: InputMaybe<Array<CategoryType>>;
};

export type QueryCategoryArgs = {
	id: Scalars["ID"]["input"];
};

export type QueryCountriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryEventArgs = {
	id: Scalars["ID"]["input"];
};

export type QueryEventsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	term?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryMerchProductArgs = {
	id: Scalars["ID"]["input"];
};

export type QueryMerchProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	term?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryNewsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	categories?: InputMaybe<Array<Scalars["String"]["input"]>>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	term?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryOrderArgs = {
	id: Scalars["ID"]["input"];
};

export type QueryOrdersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPaymentMethodsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	ids: Array<Scalars["ID"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryProductArgs = {
	id: Scalars["ID"]["input"];
};

export type QueryProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	term?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRefundRequestArgs = {
	orderId: Scalars["ID"]["input"];
	productId: Scalars["ID"]["input"];
};

export type QueryResaleOfferArgs = {
	code: Scalars["String"]["input"];
};

export type QuerySearchArgs = {
	terms: Array<Scalars["String"]["input"]>;
};

export type QueryShippingMethodsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	countryCode: Scalars["String"]["input"];
	first?: InputMaybe<Scalars["Int"]["input"]>;
	ids: Array<Scalars["ID"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryTicketDetailsArgs = {
	id: Scalars["ID"]["input"];
};

export type RefundRequest = {
	containsKulturpass: Maybe<Scalars["Boolean"]["output"]>;
	hardticketCount: Scalars["Int"]["output"];
	massRefundPaused: Maybe<Scalars["Boolean"]["output"]>;
	order: Order;
	product: ProductInterface;
	refundMethod: Maybe<PaymentMethod>;
	refundableTicketCount: Scalars["Int"]["output"];
};

/** Autogenerated input type of RemoveItem */
export type RemoveItemInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	itemId: Scalars["ID"]["input"];
};

/** Autogenerated return type of RemoveItem. */
export type RemoveItemPayload = {
	cart: Cart;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

export type Request = {
	amount?: InputMaybe<Scalars["Int"]["input"]>;
	clientMutationId?: InputMaybe<Scalars["ID"]["input"]>;
	paymentCode?: InputMaybe<Scalars["String"]["input"]>;
	productVariantId: Scalars["ID"]["input"];
	seatIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
	sectionId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated input type of RequestCodeLogin */
export type RequestCodeLoginInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	email: Scalars["String"]["input"];
	redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of RequestCodeLogin. */
export type RequestCodeLoginPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of RequestEmailLogin */
export type RequestEmailLoginInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	email: Scalars["String"]["input"];
	redirectUrl: Scalars["String"]["input"];
};

/** Autogenerated return type of RequestEmailLogin. */
export type RequestEmailLoginPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of RequestItemPersonalizationChange */
export type RequestItemPersonalizationChangeInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	personalIdentifier: Scalars["String"]["input"];
	validationCode: Scalars["String"]["input"];
};

/** Autogenerated return type of RequestItemPersonalizationChange. */
export type RequestItemPersonalizationChangePayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	item: CartItem;
};

export type ResaleOffer = {
	bought: Scalars["Boolean"]["output"];
	code: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	price: Scalars["Float"]["output"];
	productVariant: ProductVariantInterface;
	public: Scalars["Boolean"]["output"];
	seat: Maybe<Seat>;
	venueSection: Maybe<VenueSection>;
};

/** The connection type for ResaleOffer. */
export type ResaleOfferConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<ResaleOfferEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<ResaleOffer>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ResaleOfferEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<ResaleOffer>;
};

export type ResaleProduct = ProductInterface & {
	artist: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use artists instead */
	artistCategory: Maybe<Artist>;
	artists: ArtistConnection;
	available: Scalars["Boolean"]["output"];
	cartLimit: Scalars["Int"]["output"];
	categories: CategoryInterfaceConnection;
	combinedProducts: Maybe<Array<Array<ProductInterface>>>;
	description: Maybe<Scalars["String"]["output"]>;
	descriptiveName: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	kulturpassUrl: Maybe<Scalars["String"]["output"]>;
	meta: Scalars["JSON"]["output"];
	name: Maybe<Scalars["String"]["output"]>;
	overlayDetailImage: Maybe<Image>;
	overlayListingImage: Maybe<Image>;
	owningCompanyAddress: Maybe<Address>;
	personalizationRequirement: PersonalizationRequirement;
	presaleAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	price: Maybe<Scalars["Float"]["output"]>;
	productVariant: Maybe<ProductVariantInterface>;
	productVariants: ProductVariantInterfaceConnection;
	publicShippableFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	resaleOffers: ResaleOfferConnection;
	resaleOffersAvailable: Scalars["Boolean"]["output"];
	resaleSetting: ResaleSetting;
	shippingRequirements: Array<ShippingRequirement>;
	sku: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
};

export type ResaleProductArtistsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ResaleProductCategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ResaleProductPriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResaleProductProductVariantArgs = {
	id: Scalars["ID"]["input"];
};

export type ResaleProductProductVariantsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ResaleProductResaleOffersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ResaleSetting = {
	preference: ResaleSettingPreference;
	visibility: Array<ResaleSettingVisibility>;
};

export type ResaleSettingPreference = "ALLOW" | "OFF" | "ON_SOLD_OUT";

export type ResaleSettingVisibility = "PRIVATE_LINK" | "PUBLIC_OFFER";

/** Autogenerated input type of ResumeSession */
export type ResumeSessionInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	id: Scalars["ID"]["input"];
};

/** Autogenerated return type of ResumeSession. */
export type ResumeSessionPayload = {
	cart: Maybe<Cart>;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	session: Session;
};

export type SearchResult = {
	events: EventConnection;
	merchProducts: MerchProductConnection;
};

export type SearchResultEventsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SearchResultMerchProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Seat = {
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	row: Scalars["String"]["output"];
	venueSection: Maybe<VenueSection>;
	venueSectionId: Maybe<Scalars["ID"]["output"]>;
};

export type Session = {
	catalogWaitingIndex: Scalars["Int"]["output"];
	expiresAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	id: Scalars["ID"]["output"];
	locale: Scalars["String"]["output"];
	user: Maybe<User>;
	userId: Maybe<Scalars["ID"]["output"]>;
	validUntil: Scalars["ISO8601DateTime"]["output"];
	waitingIndex: Scalars["Int"]["output"];
};

export type ShippingMethod = {
	description: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	price: Scalars["Float"]["output"];
};

/** The connection type for ShippingMethod. */
export type ShippingMethodConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<ShippingMethodEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<ShippingMethod>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingMethodEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<ShippingMethod>;
};

export type ShippingProduct = ProductInterface & {
	artist: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use artists instead */
	artistCategory: Maybe<Artist>;
	artists: ArtistConnection;
	available: Scalars["Boolean"]["output"];
	cartLimit: Scalars["Int"]["output"];
	categories: CategoryInterfaceConnection;
	combinedProducts: Maybe<Array<Array<ProductInterface>>>;
	description: Scalars["String"]["output"];
	descriptiveName: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	kulturpassUrl: Maybe<Scalars["String"]["output"]>;
	meta: Scalars["JSON"]["output"];
	name: Scalars["String"]["output"];
	overlayDetailImage: Maybe<Image>;
	overlayListingImage: Maybe<Image>;
	owningCompanyAddress: Maybe<Address>;
	personalizationRequirement: PersonalizationRequirement;
	presaleAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	price: Maybe<Scalars["Float"]["output"]>;
	productVariant: Maybe<ProductVariantInterface>;
	productVariants: ProductVariantInterfaceConnection;
	publicShippableFrom: Maybe<Scalars["ISO8601DateTime"]["output"]>;
	resaleOffers: ResaleOfferConnection;
	resaleOffersAvailable: Scalars["Boolean"]["output"];
	resaleSetting: ResaleSetting;
	shippingRequirements: Array<ShippingRequirement>;
	sku: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
};

export type ShippingProductArtistsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ShippingProductCategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ShippingProductPriceArgs = {
	countryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ShippingProductProductVariantArgs = {
	id: Scalars["ID"]["input"];
};

export type ShippingProductProductVariantsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ShippingProductResaleOffersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ShippingRequirement =
	/** Recipient of the product must be verified to be at least 18 years old to receive the delivery. */
	"over_18";

export type ShopInfo = {
	defaultLocale: Scalars["String"]["output"];
	domain: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	meta: Scalars["JSON"]["output"];
	name: Scalars["String"]["output"];
	orderMergingAllowed: Scalars["Boolean"]["output"];
	paymentBlacklist: Array<Scalars["String"]["output"]>;
	sitemap: Scalars["Boolean"]["output"];
	slug: Scalars["String"]["output"];
	waitingQueue: Scalars["Boolean"]["output"];
};

export type SortDirection = "ASCENDING" | "DESCENDING";

/** Autogenerated input type of SubmitRefundRequest */
export type SubmitRefundRequestInput = {
	bankAccount?: InputMaybe<BankAccountInput>;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	email?: InputMaybe<Scalars["String"]["input"]>;
	orderId: Scalars["ID"]["input"];
	productId: Scalars["ID"]["input"];
	validationCodes: Array<Scalars["String"]["input"]>;
};

/** Autogenerated return type of SubmitRefundRequest. */
export type SubmitRefundRequestPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	paymentAmount: Scalars["Float"]["output"];
	paymentReference: Scalars["String"]["output"];
};

export type TicketInfo = {
	event: Event;
	eventId: Scalars["ID"]["output"];
	id: Scalars["ID"]["output"];
	personalIdentifier: Maybe<Scalars["String"]["output"]>;
	seat: Maybe<Seat>;
	seatId: Maybe<Scalars["ID"]["output"]>;
	validationCode: Scalars["String"]["output"];
};

export type Tour = CategoryInterface & {
	abstract: Maybe<Scalars["String"]["output"]>;
	alternativeNames: Array<Scalars["String"]["output"]>;
	artists: ArtistConnection;
	description: Maybe<Scalars["String"]["output"]>;
	endDate: Maybe<Scalars["ISO8601Date"]["output"]>;
	events: EventConnection;
	featuredAt: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	landscapeImage: Maybe<Image>;
	mainCategory: Scalars["Boolean"]["output"];
	merchProducts: MerchProductConnection;
	name: Scalars["String"]["output"];
	order: Scalars["Int"]["output"];
	parentCategory: CategoryInterface;
	parentCategoryId: Scalars["ID"]["output"];
	portraitImage: Maybe<Image>;
	shopId: Scalars["ID"]["output"];
	slug: Scalars["String"]["output"];
	sortOrderHomepage: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketBottom: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopLeft: Maybe<Scalars["Int"]["output"]>;
	sortOrderTicketTopRight: Maybe<Scalars["Int"]["output"]>;
	squareImage: Maybe<Image>;
	startDate: Maybe<Scalars["ISO8601Date"]["output"]>;
	subcategories: CategoryInterfaceConnection;
	subtitle: Maybe<Scalars["String"]["output"]>;
	subtitleInShop: Scalars["Boolean"]["output"];
	ticketImage: Maybe<Image>;
	type: CategoryType;
};

export type TourArtistsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TourEventsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type TourMerchProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	sortBy?: InputMaybe<CategoryProductSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type TourSubcategoriesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Tour. */
export type TourConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<TourEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<Tour>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TourEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<Tour>;
};

/** Autogenerated input type of UpdatePersonalIdentifier */
export type UpdatePersonalIdentifierInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	itemId: Scalars["ID"]["input"];
	personalIdentifier: Scalars["String"]["input"];
};

/** Autogenerated return type of UpdatePersonalIdentifier. */
export type UpdatePersonalIdentifierPayload = {
	cartItem: CartItem;
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of UpdatePreferredPaymentMethod */
export type UpdatePreferredPaymentMethodInput = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
	orderId: Scalars["ID"]["input"];
	preferredPaymentMethod: Scalars["String"]["input"];
};

/** Autogenerated return type of UpdatePreferredPaymentMethod. */
export type UpdatePreferredPaymentMethodPayload = {
	/** A unique identifier for the client performing the mutation. */
	clientMutationId: Maybe<Scalars["String"]["output"]>;
	order: Order;
};

export type User = {
	email: Scalars["String"]["output"];
	firstName: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	lastName: Maybe<Scalars["String"]["output"]>;
	locale: Maybe<Scalars["String"]["output"]>;
};

export type Venue = {
	address: Address;
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
};

export type VenueSection = {
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	seatsCount: Scalars["Int"]["output"];
};

/** The connection type for VenueSection. */
export type VenueSectionConnection = {
	/** A list of edges. */
	edges: Maybe<Array<Maybe<VenueSectionEdge>>>;
	/** A list of nodes. */
	nodes: Maybe<Array<Maybe<VenueSection>>>;
	/** Information to aid in pagination. */
	pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VenueSectionEdge = {
	/** A cursor for use in pagination. */
	cursor: Scalars["String"]["output"];
	/** The item at the end of the edge. */
	node: Maybe<VenueSection>;
};

export type OrderListFragment = ({
	balance: number;
	cancelAt: string | null;
	id: string;
	orderedAt: string | null;
	reference: string;
	status: OrderStatus;
	deliveries: {
		nodes: Array<{
			id: string;
			status: DeliveryStatus;
			trackingCode: string | null;
			address: { " $fragmentRefs"?: { FormattedAddressFragment: FormattedAddressFragment } };
		} | null> | null;
	};
	invoices: {
		nodes: Array<{
			id: string;
			reference: string;
			address: { " $fragmentRefs"?: { FormattedAddressFragment: FormattedAddressFragment } };
		} | null> | null;
	};
	items: Array<
		{
			invalidated: boolean;
			total: number;
			product:
				| { __typename: "BundleProduct" }
				| { __typename: "DiscountProduct" }
				| { __typename: "Event" }
				| { __typename: "MerchProduct" }
				| { __typename: "ResaleProduct" }
				| { __typename: "ShippingProduct" };
		} & { " $fragmentRefs"?: { CartItemListFragment: CartItemListFragment } }
	>;
	preferredPaymentMethod: { id: string; name: string } | null;
	shop: { meta: Record<string, unknown>; name: string } | null;
} & { " $fragmentRefs"?: { StatusHeaderFragment: StatusHeaderFragment } }) & { " $fragmentName"?: "OrderListFragment" };

export type StatusHeaderFragment = {
	balance: number;
	paymentLink: string;
	status: OrderStatus;
	deliveries: { nodes: Array<{ trackingCode: string | null } | null> | null };
	items: Array<{
		invalidated: boolean;
		ticketUrl: string | null;
		product:
			| { __typename: "BundleProduct"; meta: Record<string, unknown>; publicShippableFrom: string | null }
			| { __typename: "DiscountProduct"; meta: Record<string, unknown>; publicShippableFrom: string | null }
			| { __typename: "Event"; meta: Record<string, unknown>; publicShippableFrom: string | null }
			| { __typename: "MerchProduct"; meta: Record<string, unknown>; publicShippableFrom: string | null }
			| { __typename: "ResaleProduct"; meta: Record<string, unknown>; publicShippableFrom: string | null }
			| { __typename: "ShippingProduct"; meta: Record<string, unknown>; publicShippableFrom: string | null };
		productVariant: { physical: boolean } | { physical: boolean };
	}>;
} & { " $fragmentName"?: "StatusHeaderFragment" };

export type TicketListFragment = {
	reference: string;
	items: Array<
		{
			id: string;
			product:
				| { __typename: "BundleProduct"; id: string }
				| { __typename: "DiscountProduct"; id: string }
				| { __typename: "Event"; happeningFrom: string; happeningUntil: string; id: string }
				| { __typename: "MerchProduct"; id: string }
				| { __typename: "ResaleProduct"; id: string }
				| { __typename: "ShippingProduct"; id: string };
		} & { " $fragmentRefs"?: { EventFragment: EventFragment } }
	>;
} & { " $fragmentName"?: "TicketListFragment" };

export type CompareTicketLocationFragment = {
	seat: { row: string; name: string; venueSection: { name: string } | null } | null;
	section: { name: string } | null;
} & { " $fragmentName"?: "CompareTicketLocationFragment" };

export type EventFragment = ({
	id: string;
	product: { " $fragmentRefs"?: { EventHeaderFragment: EventHeaderFragment } } | {};
} & {
	" $fragmentRefs"?: {
		TicketListItemFragment: TicketListItemFragment;
		CompareTicketLocationFragment: CompareTicketLocationFragment;
		TicketCheckboxesFragment: TicketCheckboxesFragment;
	};
}) & { " $fragmentName"?: "EventFragment" };

export type EventHeaderFragment = {
	artist: string | null;
	happeningFrom: string;
	name: string | null;
	slug: string;
	venue: { name: string; address: { city: string | null } };
	image: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
} & { " $fragmentName"?: "EventHeaderFragment" };

export type TicketListItemFragment = ({
	id: string;
	invalidated: boolean;
	ticketUrl: string | null;
	resaleAvailable: boolean;
	product:
		| { __typename: "BundleProduct"; slug: string }
		| { __typename: "DiscountProduct"; slug: string }
		| {
				__typename: "Event";
				artist: string | null;
				descriptiveName: string;
				happeningFrom: string;
				slug: string;
				venue: { address: { city: string | null } };
		  }
		| { __typename: "MerchProduct"; slug: string }
		| { __typename: "ResaleProduct"; slug: string }
		| { __typename: "ShippingProduct"; slug: string };
	productVariant: { physical: boolean } | { physical: boolean };
	resaleOffer: { public: boolean; bought: boolean } | null;
} & {
	" $fragmentRefs"?: {
		TicketLocationTextFragment: TicketLocationTextFragment;
		ItemDescriptionFragment: ItemDescriptionFragment;
	};
}) & { " $fragmentName"?: "TicketListItemFragment" };

export type ItemDescriptionFragment = ({
	validationCode: string | null;
	invalidated: boolean;
	seat: { row: string; name: string; venueSection: { name: string } | null } | null;
	section: { name: string } | null;
	resaleOffer: { public: boolean; bought: boolean } | null;
} & { " $fragmentRefs"?: { TicketLocationTextFragment: TicketLocationTextFragment } }) & {
	" $fragmentName"?: "ItemDescriptionFragment";
};

export type AccountPageQueryVariables = Exact<{ [key: string]: never }>;

export type AccountPageQuery = {
	session: {
		validUntil: string;
		user: { email: string; firstName: string | null; id: string; lastName: string | null } | null;
	};
	orders: {
		nodes: Array<
			| ({ status: OrderStatus; items: Array<{ id: string }> } & {
					" $fragmentRefs"?: { OrderListFragment: OrderListFragment; TicketListFragment: TicketListFragment };
			  })
			| null
		> | null;
	};
};

export type CancelResaleMutationVariables = Exact<{
	input: CancelResaleInput;
}>;

export type CancelResaleMutation = {
	cancelResale: {
		clientMutationId: string | null;
		items: Array<{ " $fragmentRefs"?: { FieldsUpdatedByMutationFragment: FieldsUpdatedByMutationFragment } }>;
	} | null;
};

export type CreateResaleMutationVariables = Exact<{
	input: OfferForResaleInput;
}>;

export type CreateResaleMutation = {
	offerForResale: {
		clientMutationId: string | null;
		items: Array<{ " $fragmentRefs"?: { FieldsUpdatedByMutationFragment: FieldsUpdatedByMutationFragment } }>;
	} | null;
};

export type FieldsUpdatedByMutationFragment = {
	id: string;
	resaleAvailable: boolean;
	resaleOffer: { code: string; id: string; price: number; public: boolean; bought: boolean } | null;
} & { " $fragmentName"?: "FieldsUpdatedByMutationFragment" };

export type TicketCheckboxesFragment = ({
	id: string;
	resaleAvailable: boolean;
	resaleOffer: { code: string; public: boolean; bought: boolean } | null;
	product:
		| { slug: string }
		| { slug: string }
		| { slug: string }
		| { slug: string }
		| { slug: string }
		| { slug: string };
} & { " $fragmentRefs"?: { ItemDescriptionFragment: ItemDescriptionFragment } }) & {
	" $fragmentName"?: "TicketCheckboxesFragment";
};

export type FinishLoginMutationVariables = Exact<{
	code: Scalars["String"]["input"];
	email: Scalars["String"]["input"];
}>;

export type FinishLoginMutation = { finishCodeLogin: { clientMutationId: string | null } | null };

export type RequestLoginMutationVariables = Exact<{
	email: Scalars["String"]["input"];
	redirectUrl: Scalars["String"]["input"];
}>;

export type RequestLoginMutation = { requestCodeLogin: { clientMutationId: string | null } | null };

export type OrderPageMetadataQueryVariables = Exact<{
	id: Scalars["ID"]["input"];
}>;

export type OrderPageMetadataQuery = { order: { reference: string } };

export type OrderPageQueryVariables = Exact<{
	id: Scalars["ID"]["input"];
}>;

export type OrderPageQuery = {
	order: { status: OrderStatus; items: Array<{ id: string }> } & {
		" $fragmentRefs"?: { OrderListFragment: OrderListFragment; TicketListFragment: TicketListFragment };
	};
};

export type BandshopLayoutMetadataQueryVariables = Exact<{ [key: string]: never }>;

export type BandshopLayoutMetadataQuery = { shop: { meta: Record<string, unknown>; name: string } };

export type BandshopPageQueryVariables = Exact<{ [key: string]: never }>;

export type BandshopPageQuery = {
	categories: {
		nodes: Array<
			| { __typename: "Artist"; id: string; mainCategory: boolean; slug: string }
			| { __typename: "Brand"; id: string; mainCategory: boolean; slug: string }
			| { __typename: "Category"; id: string; mainCategory: boolean; slug: string }
			| { __typename: "Label"; id: string; mainCategory: boolean; slug: string }
			| { __typename: "Tour"; id: string; mainCategory: boolean; slug: string }
			| null
		> | null;
	};
};

export type ConfirmOrderPaymentMutationVariables = Exact<{
	orderId: Scalars["ID"]["input"];
}>;

export type ConfirmOrderPaymentMutation = { confirmOrderPayment: { clientMutationId: string | null } | null };

export type PaymentQueryVariables = Exact<{
	ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
	countryCode: Scalars["String"]["input"];
}>;

export type PaymentQuery = { paymentMethods: { nodes: Array<{ id: string } | null> | null } };

export type ShippingQueryVariables = Exact<{
	ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
	countryCode: Scalars["String"]["input"];
}>;

export type ShippingQuery = {
	shippingMethods: { nodes: Array<{ id: string; name: string; price: number } | null> | null };
};

export type NoteListFragment = {
	product:
		| { __typename: "BundleProduct"; publicShippableFrom: string | null }
		| { __typename: "DiscountProduct"; publicShippableFrom: string | null }
		| { __typename: "Event"; happeningFrom: string; publicShippableFrom: string | null }
		| { __typename: "MerchProduct"; publicShippableFrom: string | null }
		| { __typename: "ResaleProduct"; publicShippableFrom: string | null }
		| { __typename: "ShippingProduct"; publicShippableFrom: string | null };
	productVariant: { physical: boolean } | { physical: boolean };
} & { " $fragmentName"?: "NoteListFragment" };

export type PlaceOrderMutationVariables = Exact<{
	input: PlaceOrderInput;
}>;

export type PlaceOrderMutation = { placeOrder: { cart: { id: string; reference: string | null } } | null };

export type EventDetailsQueryVariables = Exact<{
	eventId: Scalars["ID"]["input"];
}>;

export type EventDetailsQuery = {
	event: {
		__typename: "Event";
		artist: string | null;
		available: boolean;
		cartLimit: number;
		description: string | null;
		descriptiveName: string;
		displayMap: boolean;
		doorTime: string | null;
		happeningFrom: string;
		happeningUntil: string;
		id: string;
		kulturpassUrl: string | null;
		localOrganizer: string | null;
		meta: Record<string, unknown>;
		name: string | null;
		presaleAt: string | null;
		price: number | null;
		seatmapUrl: string | null;
		soldOutMessage: string | null;
		stageTime: string | null;
		tourOrganizer: string | null;
		image: ({ urlPreview: string } & { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } }) | null;
		overlayDetailImage: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
		owningCompanyAddress: { " $fragmentRefs"?: { FormattedAddressFragment: FormattedAddressFragment } } | null;
		venue: {
			name: string;
			address: { city: string | null } & {
				" $fragmentRefs"?: { FormattedAddressFragment: FormattedAddressFragment };
			};
		};
		tours: { nodes: Array<{ slug: string } | null> | null };
	} & { " $fragmentRefs"?: { EventMetaFragment: EventMetaFragment } };
};

export type EventMetaDetailsQueryVariables = Exact<{
	eventId: Scalars["ID"]["input"];
}>;

export type EventMetaDetailsQuery = {
	event: {
		__typename: "Event";
		artist: string | null;
		description: string | null;
		descriptiveName: string;
		happeningFrom: string;
		happeningUntil: string;
		stageTime: string | null;
		slug: string;
		name: string | null;
		image: { urlPreview: string } | null;
		venue: { name: string; address: { city: string | null } };
	};
};

export type EventCalendarDetailsQueryVariables = Exact<{
	eventId: Scalars["ID"]["input"];
}>;

export type EventCalendarDetailsQuery = {
	event: {
		__typename: "Event";
		artist: string | null;
		name: string | null;
		doorTime: string | null;
		happeningFrom: string;
		happeningUntil: string;
		stageTime: string | null;
		venue: {
			name: string;
			address: { city: string | null } & {
				" $fragmentRefs"?: { FormattedAddressFragment: FormattedAddressFragment };
			};
		};
	};
};

export type EventVariantFragment = {
	__typename: "EventProductVariant";
	active: boolean;
	available: boolean;
	description: string | null;
	id: string;
	physical: boolean;
	price: number;
	pricingCategory: { id: string; name: string } | null;
	availableSections: {
		pageInfo: { endCursor: string | null; hasNextPage: boolean };
		nodes: Array<{ " $fragmentRefs"?: { EventVariantSectionFragment: EventVariantSectionFragment } } | null> | null;
	};
} & { " $fragmentName"?: "EventVariantFragment" };

export type EventVariantsPageQueryVariables = Exact<{
	eventId: Scalars["ID"]["input"];
	after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EventVariantsPageQuery = {
	event: {
		productVariants: {
			nodes: Array<
				| ({ __typename: "EventProductVariant" } & {
						" $fragmentRefs"?: { EventVariantFragment: EventVariantFragment };
				  })
				| { __typename: "ProductVariant" }
				| null
			> | null;
			pageInfo: { hasNextPage: boolean; endCursor: string | null };
		};
	};
};

export type EventVariantSectionFragment = { id: string; name: string } & {
	" $fragmentName"?: "EventVariantSectionFragment";
};

export type EventVariantSectionPageQueryVariables = Exact<{
	eventId: Scalars["ID"]["input"];
	variantId: Scalars["ID"]["input"];
	after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EventVariantSectionPageQuery = {
	event: {
		productVariant:
			| {
					__typename: "EventProductVariant";
					availableSections: {
						pageInfo: { endCursor: string | null; hasNextPage: boolean };
						nodes: Array<{
							" $fragmentRefs"?: { EventVariantSectionFragment: EventVariantSectionFragment };
						} | null> | null;
					};
			  }
			| { __typename: "ProductVariant" }
			| null;
	};
};

export type ProductDetailsQueryVariables = Exact<{
	productId: Scalars["ID"]["input"];
}>;

export type ProductDetailsQuery = {
	product:
		| {
				__typename: "BundleProduct";
				artist: string | null;
				available: boolean;
				cartLimit: number;
				description: string | null;
				descriptiveName: string;
				id: string;
				slug: string;
				meta: Record<string, unknown>;
				name: string | null;
				price: number | null;
				publicShippableFrom: string | null;
				shippingRequirements: Array<ShippingRequirement>;
		  }
		| {
				__typename: "DiscountProduct";
				artist: string | null;
				available: boolean;
				cartLimit: number;
				description: string | null;
				descriptiveName: string;
				id: string;
				slug: string;
				meta: Record<string, unknown>;
				name: string | null;
				price: number | null;
				publicShippableFrom: string | null;
				shippingRequirements: Array<ShippingRequirement>;
		  }
		| {
				__typename: "Event";
				artist: string | null;
				available: boolean;
				cartLimit: number;
				description: string | null;
				descriptiveName: string;
				id: string;
				slug: string;
				meta: Record<string, unknown>;
				name: string | null;
				price: number | null;
				publicShippableFrom: string | null;
				shippingRequirements: Array<ShippingRequirement>;
		  }
		| ({
				__typename: "MerchProduct";
				material: string | null;
				color: string | null;
				lowStock: boolean;
				originalPrice: number | null;
				productType: string | null;
				artist: string | null;
				available: boolean;
				cartLimit: number;
				description: string | null;
				descriptiveName: string;
				id: string;
				slug: string;
				meta: Record<string, unknown>;
				name: string | null;
				price: number | null;
				publicShippableFrom: string | null;
				shippingRequirements: Array<ShippingRequirement>;
				additionalInformation: { description: string | null; title: string | null } | null;
				details: { description: string | null; title: string | null } | null;
		  } & { " $fragmentRefs"?: { ProductMetaFragment: ProductMetaFragment } })
		| {
				__typename: "ResaleProduct";
				artist: string | null;
				available: boolean;
				cartLimit: number;
				description: string | null;
				descriptiveName: string;
				id: string;
				slug: string;
				meta: Record<string, unknown>;
				name: string | null;
				price: number | null;
				publicShippableFrom: string | null;
				shippingRequirements: Array<ShippingRequirement>;
		  }
		| {
				__typename: "ShippingProduct";
				artist: string | null;
				available: boolean;
				cartLimit: number;
				description: string;
				descriptiveName: string;
				id: string;
				slug: string;
				meta: Record<string, unknown>;
				name: string;
				price: number | null;
				publicShippableFrom: string | null;
				shippingRequirements: Array<ShippingRequirement>;
		  };
};

export type ProductDetailVariantFragment = {
	active: boolean;
	available: boolean;
	id: string;
	description: string | null;
	productId: string;
	order: number;
	price: number;
} & { " $fragmentName"?: "ProductDetailVariantFragment" };

export type ProductDetailVariantPageQueryVariables = Exact<{
	productId: Scalars["ID"]["input"];
	after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ProductDetailVariantPageQuery = {
	product:
		| {
				id: string;
				productVariants: {
					nodes: Array<
						| { " $fragmentRefs"?: { ProductDetailVariantFragment: ProductDetailVariantFragment } }
						| {}
						| null
					> | null;
					pageInfo: { hasNextPage: boolean; endCursor: string | null };
				};
		  }
		| {
				id: string;
				productVariants: {
					nodes: Array<
						| { " $fragmentRefs"?: { ProductDetailVariantFragment: ProductDetailVariantFragment } }
						| {}
						| null
					> | null;
					pageInfo: { hasNextPage: boolean; endCursor: string | null };
				};
		  }
		| {
				id: string;
				productVariants: {
					nodes: Array<
						| { " $fragmentRefs"?: { ProductDetailVariantFragment: ProductDetailVariantFragment } }
						| {}
						| null
					> | null;
					pageInfo: { hasNextPage: boolean; endCursor: string | null };
				};
		  }
		| {
				id: string;
				productVariants: {
					nodes: Array<
						| { " $fragmentRefs"?: { ProductDetailVariantFragment: ProductDetailVariantFragment } }
						| {}
						| null
					> | null;
					pageInfo: { hasNextPage: boolean; endCursor: string | null };
				};
		  }
		| {
				id: string;
				productVariants: {
					nodes: Array<
						| { " $fragmentRefs"?: { ProductDetailVariantFragment: ProductDetailVariantFragment } }
						| {}
						| null
					> | null;
					pageInfo: { hasNextPage: boolean; endCursor: string | null };
				};
		  }
		| {
				id: string;
				productVariants: {
					nodes: Array<
						| { " $fragmentRefs"?: { ProductDetailVariantFragment: ProductDetailVariantFragment } }
						| {}
						| null
					> | null;
					pageInfo: { hasNextPage: boolean; endCursor: string | null };
				};
		  };
};

export type ProductDetailImageFragment = ({ tags: Array<string>; urlPreview: string } & {
	" $fragmentRefs"?: { KsImagesWithTagsFragment: KsImagesWithTagsFragment };
}) & { " $fragmentName"?: "ProductDetailImageFragment" };

export type ProductDetailImagePageQueryVariables = Exact<{
	productId: Scalars["ID"]["input"];
	after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ProductDetailImagePageQuery = {
	product:
		| { __typename: "BundleProduct" }
		| { __typename: "DiscountProduct" }
		| { __typename: "Event" }
		| {
				__typename: "MerchProduct";
				images: {
					nodes: Array<{
						" $fragmentRefs"?: { ProductDetailImageFragment: ProductDetailImageFragment };
					} | null> | null;
					pageInfo: { hasNextPage: boolean; endCursor: string | null };
				};
		  }
		| { __typename: "ResaleProduct" }
		| { __typename: "ShippingProduct" };
};

export type TourDetailsQueryVariables = Exact<{
	tourId: Scalars["ID"]["input"];
}>;

export type TourDetailsQuery = {
	category:
		| { __typename: "Artist"; description: string | null; id: string; name: string; subtitle: string | null }
		| { __typename: "Brand"; description: string | null; id: string; name: string; subtitle: string | null }
		| { __typename: "Category"; description: string | null; id: string; name: string; subtitle: string | null }
		| { __typename: "Label"; description: string | null; id: string; name: string; subtitle: string | null }
		| {
				__typename: "Tour";
				description: string | null;
				id: string;
				name: string;
				subtitle: string | null;
				ticketImage: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
		  };
};

export type HomeNewsFragment = {
	text: string | null;
	title: string | null;
	bgColor: string | null;
	image: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
} & { " $fragmentName"?: "HomeNewsFragment" };

type HomeArtists_Artist_Fragment = {
	name: string;
	slug: string;
	squareImage: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
} & { " $fragmentName"?: "HomeArtists_Artist_Fragment" };

type HomeArtists_Brand_Fragment = {
	name: string;
	slug: string;
	squareImage: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
} & { " $fragmentName"?: "HomeArtists_Brand_Fragment" };

type HomeArtists_Category_Fragment = {
	name: string;
	slug: string;
	squareImage: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
} & { " $fragmentName"?: "HomeArtists_Category_Fragment" };

type HomeArtists_Label_Fragment = {
	name: string;
	slug: string;
	squareImage: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
} & { " $fragmentName"?: "HomeArtists_Label_Fragment" };

type HomeArtists_Tour_Fragment = {
	name: string;
	slug: string;
	squareImage: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
} & { " $fragmentName"?: "HomeArtists_Tour_Fragment" };

export type HomeArtistsFragment =
	| HomeArtists_Artist_Fragment
	| HomeArtists_Brand_Fragment
	| HomeArtists_Category_Fragment
	| HomeArtists_Label_Fragment
	| HomeArtists_Tour_Fragment;

export type KrasserstoffHomePageQueryVariables = Exact<{ [key: string]: never }>;

export type KrasserstoffHomePageQuery = {
	categories: {
		nodes: Array<
			| ({ mainCategory: boolean } & {
					" $fragmentRefs"?: { HomeArtists_Artist_Fragment: HomeArtists_Artist_Fragment };
			  })
			| ({ mainCategory: boolean } & {
					" $fragmentRefs"?: { HomeArtists_Brand_Fragment: HomeArtists_Brand_Fragment };
			  })
			| ({ mainCategory: boolean } & {
					" $fragmentRefs"?: { HomeArtists_Category_Fragment: HomeArtists_Category_Fragment };
			  })
			| ({ mainCategory: boolean } & {
					" $fragmentRefs"?: { HomeArtists_Label_Fragment: HomeArtists_Label_Fragment };
			  })
			| ({ mainCategory: boolean } & {
					" $fragmentRefs"?: { HomeArtists_Tour_Fragment: HomeArtists_Tour_Fragment };
			  })
			| null
		> | null;
	};
	news: { nodes: Array<{ " $fragmentRefs"?: { HomeNewsFragment: HomeNewsFragment } } | null> | null };
};

export type SeatmapMetadataQueryVariables = Exact<{
	eventSlug: Scalars["ID"]["input"];
}>;

export type SeatmapMetadataQuery = { event: { descriptiveName: string } };

export type SeatmapSvgQueryVariables = Exact<{
	eventSlug: Scalars["ID"]["input"];
}>;

export type SeatmapSvgQuery = {
	event: {
		availableSeatIds: Array<string>;
		descriptiveName: string;
		seatmapUrl: string | null;
		productVariants: {
			nodes: Array<
				| {
						__typename: "EventProductVariant";
						availableSectionIds: Array<string>;
						available: boolean;
						id: string;
						pricingCategory: { id: string; name: string } | null;
				  }
				| { __typename: "ProductVariant"; available: boolean; id: string }
				| null
			> | null;
		};
	};
};

export type SeatmapPageQueryVariables = Exact<{
	eventSlug: Scalars["ID"]["input"];
}>;

export type SeatmapPageQuery = { event: { descriptiveName: string; displayMap: boolean; name: string | null } };

export type FormattedAddressFragment = {
	address: string | null;
	address2: string | null;
	city: string | null;
	company: string | null;
	firstName: string | null;
	lastName: string | null;
	postCode: string | null;
	country: { countryCode: string; name: string } | null;
} & { " $fragmentName"?: "FormattedAddressFragment" };

export type TicketLocationTextFragment = {
	seat: { name: string; row: string; venueSection: { name: string } | null } | null;
	section: { name: string } | null;
} & { " $fragmentName"?: "TicketLocationTextFragment" };

export type BandshopNewsQueryVariables = Exact<{ [key: string]: never }>;

export type BandshopNewsQuery = {
	news: {
		nodes: Array<{
			url: string | null;
			id: string;
			title: string | null;
			text: string | null;
			image: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
		} | null> | null;
	};
};

export type BandshopNewsTitleQueryVariables = Exact<{ [key: string]: never }>;

export type BandshopNewsTitleQuery = {
	news: { nodes: Array<{ title: string | null; text: string | null; url: string | null } | null> | null };
};

export type CategoryCardQueryVariables = Exact<{
	id: Scalars["ID"]["input"];
	after: Scalars["String"]["input"];
}>;

export type CategoryCardQuery = {
	category:
		| { __typename: "Artist"; name: string }
		| { __typename: "Brand"; name: string }
		| {
				__typename: "Category";
				name: string;
				merchProducts: {
					nodes: Array<
						({ id: string } & { " $fragmentRefs"?: { ProductCardFragment: ProductCardFragment } }) | null
					> | null;
					pageInfo: { endCursor: string | null; hasNextPage: boolean };
				};
		  }
		| { __typename: "Label"; name: string }
		| { __typename: "Tour"; name: string };
};

export type CategoryEventsQueryVariables = Exact<{
	tourId: Scalars["ID"]["input"];
	after: Scalars["String"]["input"];
}>;

export type CategoryEventsQuery = {
	category:
		| {
				events: {
					nodes: Array<{
						" $fragmentRefs"?: { EventListEventFragment: EventListEventFragment };
					} | null> | null;
					pageInfo: { endCursor: string | null; hasNextPage: boolean };
				};
		  }
		| {
				events: {
					nodes: Array<{
						" $fragmentRefs"?: { EventListEventFragment: EventListEventFragment };
					} | null> | null;
					pageInfo: { endCursor: string | null; hasNextPage: boolean };
				};
		  }
		| {
				events: {
					nodes: Array<{
						" $fragmentRefs"?: { EventListEventFragment: EventListEventFragment };
					} | null> | null;
					pageInfo: { endCursor: string | null; hasNextPage: boolean };
				};
		  }
		| {
				events: {
					nodes: Array<{
						" $fragmentRefs"?: { EventListEventFragment: EventListEventFragment };
					} | null> | null;
					pageInfo: { endCursor: string | null; hasNextPage: boolean };
				};
		  }
		| {
				events: {
					nodes: Array<{
						" $fragmentRefs"?: { EventListEventFragment: EventListEventFragment };
					} | null> | null;
					pageInfo: { endCursor: string | null; hasNextPage: boolean };
				};
		  };
};

export type EventListDetailsQueryVariables = Exact<{
	tourId: Scalars["ID"]["input"];
}>;

export type EventListDetailsQuery = {
	category:
		| { __typename: "Artist"; id: string; name: string; subtitle: string | null }
		| { __typename: "Brand"; id: string; name: string; subtitle: string | null }
		| { __typename: "Category"; id: string; name: string; subtitle: string | null }
		| { __typename: "Label"; id: string; name: string; subtitle: string | null }
		| {
				__typename: "Tour";
				id: string;
				name: string;
				subtitle: string | null;
				ticketImage: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
		  };
};

export type EventListEventFragment = ({
	artist: string | null;
	available: boolean;
	happeningFrom: string;
	happeningUntil: string;
	id: string;
	meta: Record<string, unknown>;
	name: string | null;
	presaleAt: string | null;
	price: number | null;
	slug: string;
	soldOutMessage: string | null;
	productVariants: { nodes: Array<{ id: string; price: number } | { id: string; price: number } | null> | null };
	venue: { id: string; name: string; address: { city: string | null } };
} & { " $fragmentRefs"?: { EventMetaFragment: EventMetaFragment } }) & { " $fragmentName"?: "EventListEventFragment" };

export type BandshopFilterQueryVariables = Exact<{ [key: string]: never }>;

export type BandshopFilterQuery = {
	categories: {
		nodes: Array<
			| { __typename: "Artist"; id: string; name: string; slug: string; mainCategory: boolean }
			| { __typename: "Brand"; id: string; name: string; slug: string; mainCategory: boolean }
			| {
					__typename: "Category";
					id: string;
					name: string;
					slug: string;
					mainCategory: boolean;
					merchProducts: { nodes: Array<{ id: string } | null> | null };
			  }
			| { __typename: "Label"; id: string; name: string; slug: string; mainCategory: boolean }
			| {
					__typename: "Tour";
					id: string;
					name: string;
					slug: string;
					mainCategory: boolean;
					events: { nodes: Array<{ id: string } | null> | null };
			  }
			| null
		> | null;
	};
};

export type KsImageFragment = {
	alt: string | null;
	height: number | null;
	thumbhash: string | null;
	width: number | null;
	url16: string;
	url32: string;
	url48: string;
	url64: string;
	url96: string;
	url128: string;
	url256: string;
	url384: string;
	url390: string;
	url485: string;
	url834: string;
	url1024: string;
	url1280: string;
	url1440: string;
	url1920: string;
	url2560: string;
	url3440: string;
} & { " $fragmentName"?: "KsImageFragment" };

export type ProductCardFragment = ({
	artist: string | null;
	id: string;
	name: string | null;
	meta: Record<string, unknown>;
	originalPrice: number | null;
	price: number | null;
	productType: string | null;
	color: string | null;
	slug: string;
	images: {
		nodes: Array<{ " $fragmentRefs"?: { KsImagesWithTagsFragment: KsImagesWithTagsFragment } } | null> | null;
	};
} & { " $fragmentRefs"?: { ProductMetaFragment: ProductMetaFragment } }) & { " $fragmentName"?: "ProductCardFragment" };

export type CartItemListFragment = ({
	bundledItemIds: Array<string>;
	id: string;
	invalidated: boolean;
	seatId: string | null;
	total: number;
	product:
		| {
				__typename: "BundleProduct";
				artist: string | null;
				descriptiveName: string;
				id: string;
				meta: Record<string, unknown>;
				name: string | null;
		  }
		| {
				__typename: "DiscountProduct";
				artist: string | null;
				descriptiveName: string;
				id: string;
				meta: Record<string, unknown>;
				name: string | null;
		  }
		| {
				__typename: "Event";
				happeningFrom: string;
				artist: string | null;
				descriptiveName: string;
				id: string;
				meta: Record<string, unknown>;
				name: string | null;
				image: { " $fragmentRefs"?: { KsImageFragment: KsImageFragment } } | null;
				venue: { name: string; address: { city: string | null } };
		  }
		| {
				__typename: "MerchProduct";
				material: string | null;
				color: string | null;
				productType: string | null;
				artist: string | null;
				descriptiveName: string;
				id: string;
				meta: Record<string, unknown>;
				name: string | null;
				images: {
					nodes: Array<{
						" $fragmentRefs"?: { KsImagesWithTagsFragment: KsImagesWithTagsFragment };
					} | null> | null;
				};
		  }
		| {
				__typename: "ResaleProduct";
				artist: string | null;
				descriptiveName: string;
				id: string;
				meta: Record<string, unknown>;
				name: string | null;
		  }
		| {
				__typename: "ShippingProduct";
				artist: string | null;
				descriptiveName: string;
				id: string;
				meta: Record<string, unknown>;
				name: string;
		  };
	productVariant:
		| { description: string | null; id: string; physical: boolean }
		| { description: string | null; id: string; physical: boolean };
} & {
	" $fragmentRefs"?: { CartItemFragment: CartItemFragment; TicketLocationTextFragment: TicketLocationTextFragment };
}) & { " $fragmentName"?: "CartItemListFragment" };

export type CartItemFragment = {
	id: string;
	invalidated: boolean;
	product:
		| {
				__typename: "BundleProduct";
				slug: string;
				artist: string | null;
				cartLimit: number;
				descriptiveName: string;
				publicShippableFrom: string | null;
		  }
		| {
				__typename: "DiscountProduct";
				slug: string;
				artist: string | null;
				cartLimit: number;
				descriptiveName: string;
				publicShippableFrom: string | null;
		  }
		| {
				__typename: "Event";
				slug: string;
				artist: string | null;
				cartLimit: number;
				descriptiveName: string;
				publicShippableFrom: string | null;
		  }
		| {
				__typename: "MerchProduct";
				slug: string;
				artist: string | null;
				cartLimit: number;
				descriptiveName: string;
				publicShippableFrom: string | null;
		  }
		| {
				__typename: "ResaleProduct";
				slug: string;
				artist: string | null;
				cartLimit: number;
				descriptiveName: string;
				publicShippableFrom: string | null;
		  }
		| {
				__typename: "ShippingProduct";
				slug: string;
				artist: string | null;
				cartLimit: number;
				descriptiveName: string;
				publicShippableFrom: string | null;
		  };
	productVariant: { id: string } | { id: string };
} & { " $fragmentName"?: "CartItemFragment" };

export type EventMetaFragment = {
	artist: string | null;
	available: boolean;
	happeningFrom: string;
	happeningUntil: string;
	meta: Record<string, unknown>;
	name: string | null;
	presaleAt: string | null;
	price: number | null;
	stageTime: string | null;
	doorTime: string | null;
	description: string | null;
	localOrganizer: string | null;
	slug: string;
	soldOutMessage: string | null;
	venue: {
		id: string;
		name: string;
		address: { city: string | null; countryCode: string | null; address: string | null; postCode: string | null };
	};
	image: { urlPreview: string } | null;
} & { " $fragmentName"?: "EventMetaFragment" };

export type ProductMetaFragment = {
	artist: string | null;
	id: string;
	name: string | null;
	meta: Record<string, unknown>;
	originalPrice: number | null;
	price: number | null;
	productType: string | null;
	color: string | null;
	slug: string;
	material: string | null;
	publicShippableFrom: string | null;
	lowStock: boolean;
	available: boolean;
	images: {
		nodes: Array<{ " $fragmentRefs"?: { KsImagesWithTagsFragment: KsImagesWithTagsFragment } } | null> | null;
	};
} & { " $fragmentName"?: "ProductMetaFragment" };

export type SessionExpiresAtQueryVariables = Exact<{ [key: string]: never }>;

export type SessionExpiresAtQuery = { session: { id: string; expiresAt: string | null } };

export type HeartbeatMutationVariables = Exact<{ [key: string]: never }>;

export type HeartbeatMutation = { heartbeat: { clientMutationId: string | null } | null };

export type UseCartCartFragment = ({
	id: string;
	countryCode: string;
	reference: string | null;
	total: number;
	items: Array<
		{ productVariantId: string; productVariant: { physical: boolean } | { physical: boolean } } & {
			" $fragmentRefs"?: { CartItemListFragment: CartItemListFragment; NoteListFragment: NoteListFragment };
		}
	>;
} & { " $fragmentRefs"?: { UseDeliveryCountryFragment: UseDeliveryCountryFragment } }) & {
	" $fragmentName"?: "UseCartCartFragment";
};

export type UseDeliveryCountryFragment = { countryCode: string } & { " $fragmentName"?: "UseDeliveryCountryFragment" };

export type UseCartQueryVariables = Exact<{ [key: string]: never }>;

export type UseCartQuery = { cart: { " $fragmentRefs"?: { UseCartCartFragment: UseCartCartFragment } } | null };

export type RemoveItemMutationVariables = Exact<{
	itemId: Scalars["ID"]["input"];
}>;

export type RemoveItemMutation = {
	removeItem: { cart: { " $fragmentRefs"?: { UseCartCartFragment: UseCartCartFragment } } } | null;
};

export type LogActivityMutationVariables = Exact<{
	action: Scalars["String"]["input"];
	details: Scalars["JSON"]["input"];
}>;

export type LogActivityMutation = { logActivity: { clientMutationId: string | null } | null };

export type AddItemsMutationVariables = Exact<{
	addItemRequests: Array<Request> | Request;
}>;

export type AddItemsMutation = {
	addItems: { cart: { " $fragmentRefs"?: { UseCartCartFragment: UseCartCartFragment } } } | null;
};

export type ChangeCountryMutationVariables = Exact<{
	countryCode: Scalars["ID"]["input"];
}>;

export type ChangeCountryMutation = {
	changeCountry: { cart: { " $fragmentRefs"?: { UseCartCartFragment: UseCartCartFragment } } } | null;
};

export type KsImagesWithTagsFragment = ({ order: number; tags: Array<string>; urlPreview: string } & {
	" $fragmentRefs"?: { KsImageFragment: KsImageFragment };
}) & { " $fragmentName"?: "KsImagesWithTagsFragment" };

export type ShopMetaQueryVariables = Exact<{ [key: string]: never }>;

export type ShopMetaQuery = { shop: { meta: Record<string, unknown> } };

export type BandshopQueryVariables = Exact<{ [key: string]: never }>;

export type BandshopQuery = { shop: { defaultLocale: string; domain: string | null; slug: string; name: string } };

export const FormattedAddressFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FormattedAddress" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "address" } },
					{ kind: "Field", name: { kind: "Name", value: "address2" } },
					{ kind: "Field", name: { kind: "Name", value: "city" } },
					{ kind: "Field", name: { kind: "Name", value: "company" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "country" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "firstName" } },
					{ kind: "Field", name: { kind: "Name", value: "lastName" } },
					{ kind: "Field", name: { kind: "Name", value: "postCode" } },
				],
			},
		},
	],
} as unknown as DocumentNode<FormattedAddressFragment, unknown>;
export const CartItemFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CartItemFragment, unknown>;
export const KsImageFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
	],
} as unknown as DocumentNode<KsImageFragment, unknown>;
export const KsImagesWithTagsFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
	],
} as unknown as DocumentNode<KsImagesWithTagsFragment, unknown>;
export const TicketLocationTextFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<TicketLocationTextFragment, unknown>;
export const CartItemListFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CartItemListFragment, unknown>;
export const StatusHeaderFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "StatusHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "balance" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "deliveries" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "trackingCode" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "meta" } },
											{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "paymentLink" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
				],
			},
		},
	],
} as unknown as DocumentNode<StatusHeaderFragment, unknown>;
export const OrderListFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "OrderList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "balance" } },
					{ kind: "Field", name: { kind: "Name", value: "cancelAt" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "deliveries" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "FormattedAddress" },
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "status" } },
											{ kind: "Field", name: { kind: "Name", value: "trackingCode" } },
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "invoices" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "FormattedAddress" },
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "reference" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItemList" } },
								{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "total" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "orderedAt" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "preferredPaymentMethod" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "shop" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "StatusHeader" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FormattedAddress" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "address" } },
					{ kind: "Field", name: { kind: "Name", value: "address2" } },
					{ kind: "Field", name: { kind: "Name", value: "city" } },
					{ kind: "Field", name: { kind: "Name", value: "company" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "country" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "firstName" } },
					{ kind: "Field", name: { kind: "Name", value: "lastName" } },
					{ kind: "Field", name: { kind: "Name", value: "postCode" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "StatusHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "balance" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "deliveries" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "trackingCode" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "meta" } },
											{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "paymentLink" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
				],
			},
		},
	],
} as unknown as DocumentNode<OrderListFragment, unknown>;
export const EventHeaderFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "city" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
	],
} as unknown as DocumentNode<EventHeaderFragment, unknown>;
export const ItemDescriptionFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ItemDescription" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "validationCode" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ItemDescriptionFragment, unknown>;
export const TicketListItemFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketListItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "artist" } },
											{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ItemDescription" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "validationCode" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
				],
			},
		},
	],
} as unknown as DocumentNode<TicketListItemFragment, unknown>;
export const CompareTicketLocationFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CompareTicketLocation" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CompareTicketLocationFragment, unknown>;
export const TicketCheckboxesFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketCheckboxes" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "code" } },
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ItemDescription" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "validationCode" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
				],
			},
		},
	],
} as unknown as DocumentNode<TicketCheckboxesFragment, unknown>;
export const EventFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "Event" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "EventHeader" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketListItem" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CompareTicketLocation" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketCheckboxes" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ItemDescription" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "validationCode" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "city" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketListItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "artist" } },
											{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CompareTicketLocation" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketCheckboxes" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "code" } },
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
				],
			},
		},
	],
} as unknown as DocumentNode<EventFragment, unknown>;
export const TicketListFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "Event" },
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "happeningFrom" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "happeningUntil" },
														},
													],
												},
											},
										],
									},
								},
								{ kind: "FragmentSpread", name: { kind: "Name", value: "Event" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "city" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ItemDescription" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "validationCode" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketListItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "artist" } },
											{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CompareTicketLocation" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketCheckboxes" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "code" } },
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "Event" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "EventHeader" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketListItem" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CompareTicketLocation" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketCheckboxes" } },
				],
			},
		},
	],
} as unknown as DocumentNode<TicketListFragment, unknown>;
export const FieldsUpdatedByMutationFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FieldsUpdatedByMutation" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "code" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "price" } },
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<FieldsUpdatedByMutationFragment, unknown>;
export const EventVariantSectionFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventVariantSection" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VenueSection" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
				],
			},
		},
	],
} as unknown as DocumentNode<EventVariantSectionFragment, unknown>;
export const EventVariantFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventVariant" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EventProductVariant" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "active" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "physical" } },
					{ kind: "Field", name: { kind: "Name", value: "price" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "pricingCategory" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "availableSections" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "pageInfo" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "endCursor" } },
											{ kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "EventVariantSection" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventVariantSection" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VenueSection" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
				],
			},
		},
	],
} as unknown as DocumentNode<EventVariantFragment, unknown>;
export const ProductDetailVariantFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductDetailVariant" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ProductVariant" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "active" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "productId" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "price" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ProductDetailVariantFragment, unknown>;
export const ProductDetailImageFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductDetailImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImagesWithTags" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ProductDetailImageFragment, unknown>;
export const HomeNewsFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "HomeNews" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "News" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", alias: { kind: "Name", value: "bgColor" }, name: { kind: "Name", value: "url" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "text" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
	],
} as unknown as DocumentNode<HomeNewsFragment, unknown>;
export const HomeArtistsFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "HomeArtists" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CategoryInterface" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "squareImage" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
	],
} as unknown as DocumentNode<HomeArtistsFragment, unknown>;
export const EventMetaFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventMeta" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "presaleAt" } },
					{ kind: "Field", name: { kind: "Name", value: "price" } },
					{ kind: "Field", name: { kind: "Name", value: "stageTime" } },
					{ kind: "Field", name: { kind: "Name", value: "doorTime" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "localOrganizer" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "soldOutMessage" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "city" } },
											{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
											{ kind: "Field", name: { kind: "Name", value: "address" } },
											{ kind: "Field", name: { kind: "Name", value: "postCode" } },
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									alias: { kind: "Name", value: "urlPreview" },
									name: { kind: "Name", value: "url" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "width" },
											value: { kind: "IntValue", value: "1024" },
										},
									],
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EventMetaFragment, unknown>;
export const EventListEventFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventListEvent" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "EventMeta" } },
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "presaleAt" } },
					{ kind: "Field", name: { kind: "Name", value: "price" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariants" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "price" } },
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "soldOutMessage" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "city" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventMeta" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "presaleAt" } },
					{ kind: "Field", name: { kind: "Name", value: "price" } },
					{ kind: "Field", name: { kind: "Name", value: "stageTime" } },
					{ kind: "Field", name: { kind: "Name", value: "doorTime" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "localOrganizer" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "soldOutMessage" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "city" } },
											{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
											{ kind: "Field", name: { kind: "Name", value: "address" } },
											{ kind: "Field", name: { kind: "Name", value: "postCode" } },
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									alias: { kind: "Name", value: "urlPreview" },
									name: { kind: "Name", value: "url" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "width" },
											value: { kind: "IntValue", value: "1024" },
										},
									],
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EventListEventFragment, unknown>;
export const ProductMetaFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductMeta" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "images" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "KSImagesWithTags" },
											},
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "originalPrice" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "price" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "productType" } },
					{ kind: "Field", name: { kind: "Name", value: "color" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "material" } },
					{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "lowStock" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ProductMetaFragment, unknown>;
export const ProductCardFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductCard" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ProductMeta" } },
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "images" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "KSImagesWithTags" },
											},
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "originalPrice" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "price" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "productType" } },
					{ kind: "Field", name: { kind: "Name", value: "color" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductMeta" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "images" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "KSImagesWithTags" },
											},
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "originalPrice" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "price" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "productType" } },
					{ kind: "Field", name: { kind: "Name", value: "color" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "material" } },
					{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "lowStock" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
				],
			},
		},
	],
} as unknown as DocumentNode<ProductCardFragment, unknown>;
export const NoteListFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "NoteList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<NoteListFragment, unknown>;
export const UseDeliveryCountryFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseDeliveryCountry" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [{ kind: "Field", name: { kind: "Name", value: "countryCode" } }],
			},
		},
	],
} as unknown as DocumentNode<UseDeliveryCountryFragment, unknown>;
export const UseCartCartFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseCartCart" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "productVariantId" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItemList" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "NoteList" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "total" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "UseDeliveryCountry" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "NoteList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseDeliveryCountry" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [{ kind: "Field", name: { kind: "Name", value: "countryCode" } }],
			},
		},
	],
} as unknown as DocumentNode<UseCartCartFragment, unknown>;
export const AccountPageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "AccountPage" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "session" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "user" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "email" } },
											{ kind: "Field", name: { kind: "Name", value: "firstName" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "lastName" } },
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "validUntil" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "orders" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "items" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "id" } },
													],
												},
											},
											{ kind: "FragmentSpread", name: { kind: "Name", value: "OrderList" } },
											{ kind: "Field", name: { kind: "Name", value: "status" } },
											{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketList" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FormattedAddress" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "address" } },
					{ kind: "Field", name: { kind: "Name", value: "address2" } },
					{ kind: "Field", name: { kind: "Name", value: "city" } },
					{ kind: "Field", name: { kind: "Name", value: "company" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "country" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "firstName" } },
					{ kind: "Field", name: { kind: "Name", value: "lastName" } },
					{ kind: "Field", name: { kind: "Name", value: "postCode" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "StatusHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "balance" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "deliveries" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "trackingCode" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "meta" } },
											{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "paymentLink" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "city" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ItemDescription" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "validationCode" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketListItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "artist" } },
											{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CompareTicketLocation" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketCheckboxes" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "code" } },
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "Event" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "EventHeader" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketListItem" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CompareTicketLocation" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketCheckboxes" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "OrderList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "balance" } },
					{ kind: "Field", name: { kind: "Name", value: "cancelAt" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "deliveries" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "FormattedAddress" },
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "status" } },
											{ kind: "Field", name: { kind: "Name", value: "trackingCode" } },
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "invoices" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "FormattedAddress" },
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "reference" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItemList" } },
								{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "total" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "orderedAt" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "preferredPaymentMethod" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "shop" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "StatusHeader" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "Event" },
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "happeningFrom" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "happeningUntil" },
														},
													],
												},
											},
										],
									},
								},
								{ kind: "FragmentSpread", name: { kind: "Name", value: "Event" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AccountPageQuery, AccountPageQueryVariables>;
export const CancelResaleDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "CancelResale" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "CancelResaleInput" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "cancelResale" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: { kind: "Variable", name: { kind: "Name", value: "input" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "clientMutationId" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "items" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "FieldsUpdatedByMutation" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FieldsUpdatedByMutation" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "code" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "price" } },
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CancelResaleMutation, CancelResaleMutationVariables>;
export const CreateResaleDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "CreateResale" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "OfferForResaleInput" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "offerForResale" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: { kind: "Variable", name: { kind: "Name", value: "input" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "clientMutationId" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "items" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "FieldsUpdatedByMutation" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FieldsUpdatedByMutation" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "code" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "price" } },
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CreateResaleMutation, CreateResaleMutationVariables>;
export const FinishLoginDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "FinishLogin" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "finishCodeLogin" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "code" },
											value: { kind: "Variable", name: { kind: "Name", value: "code" } },
										},
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "email" },
											value: { kind: "Variable", name: { kind: "Name", value: "email" } },
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "clientMutationId" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<FinishLoginMutation, FinishLoginMutationVariables>;
export const RequestLoginDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "RequestLogin" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "redirectUrl" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "requestCodeLogin" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "email" },
											value: { kind: "Variable", name: { kind: "Name", value: "email" } },
										},
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "redirectUrl" },
											value: { kind: "Variable", name: { kind: "Name", value: "redirectUrl" } },
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "clientMutationId" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<RequestLoginMutation, RequestLoginMutationVariables>;
export const OrderPageMetadataDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "OrderPageMetadata" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "order" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "reference" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<OrderPageMetadataQuery, OrderPageMetadataQueryVariables>;
export const OrderPageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "OrderPage" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "order" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "items" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
									},
								},
								{ kind: "FragmentSpread", name: { kind: "Name", value: "OrderList" } },
								{ kind: "Field", name: { kind: "Name", value: "status" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketList" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FormattedAddress" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "address" } },
					{ kind: "Field", name: { kind: "Name", value: "address2" } },
					{ kind: "Field", name: { kind: "Name", value: "city" } },
					{ kind: "Field", name: { kind: "Name", value: "company" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "country" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "firstName" } },
					{ kind: "Field", name: { kind: "Name", value: "lastName" } },
					{ kind: "Field", name: { kind: "Name", value: "postCode" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "StatusHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "balance" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "deliveries" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "trackingCode" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "meta" } },
											{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "paymentLink" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventHeader" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "city" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ItemDescription" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "validationCode" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketListItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "artist" } },
											{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "ticketUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CompareTicketLocation" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketCheckboxes" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "resaleAvailable" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resaleOffer" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "code" } },
								{ kind: "Field", name: { kind: "Name", value: "public" } },
								{ kind: "Field", name: { kind: "Name", value: "bought" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ItemDescription" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "Event" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "EventHeader" } }],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketListItem" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CompareTicketLocation" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketCheckboxes" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "OrderList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "balance" } },
					{ kind: "Field", name: { kind: "Name", value: "cancelAt" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "deliveries" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "FormattedAddress" },
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "status" } },
											{ kind: "Field", name: { kind: "Name", value: "trackingCode" } },
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "invoices" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "100" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "FormattedAddress" },
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "reference" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItemList" } },
								{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "total" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "orderedAt" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "preferredPaymentMethod" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "shop" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "StatusHeader" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Order" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "product" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "Event" },
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "happeningFrom" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "happeningUntil" },
														},
													],
												},
											},
										],
									},
								},
								{ kind: "FragmentSpread", name: { kind: "Name", value: "Event" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<OrderPageQuery, OrderPageQueryVariables>;
export const BandshopLayoutMetadataDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "BandshopLayoutMetadata" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "shop" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<BandshopLayoutMetadataQuery, BandshopLayoutMetadataQueryVariables>;
export const BandshopPageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "BandshopPage" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "categories" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "mainCategory" } },
											{ kind: "Field", name: { kind: "Name", value: "slug" } },
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<BandshopPageQuery, BandshopPageQueryVariables>;
export const ConfirmOrderPaymentDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "ConfirmOrderPayment" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "orderId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "confirmOrderPayment" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "orderId" },
											value: { kind: "Variable", name: { kind: "Name", value: "orderId" } },
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "clientMutationId" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ConfirmOrderPaymentMutation, ConfirmOrderPaymentMutationVariables>;
export const PaymentDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "Payment" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
							},
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "countryCode" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "paymentMethods" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "ids" },
								value: { kind: "Variable", name: { kind: "Name", value: "ids" } },
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "Variable", name: { kind: "Name", value: "countryCode" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PaymentQuery, PaymentQueryVariables>;
export const ShippingDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "Shipping" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
							},
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "countryCode" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "shippingMethods" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "ids" },
								value: { kind: "Variable", name: { kind: "Name", value: "ids" } },
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "Variable", name: { kind: "Name", value: "countryCode" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "name" } },
											{ kind: "Field", name: { kind: "Name", value: "price" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ShippingQuery, ShippingQueryVariables>;
export const PlaceOrderDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "PlaceOrder" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "PlaceOrderInput" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "placeOrder" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: { kind: "Variable", name: { kind: "Name", value: "input" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "cart" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "reference" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PlaceOrderMutation, PlaceOrderMutationVariables>;
export const EventDetailsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "EventDetails" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "event" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "FragmentSpread", name: { kind: "Name", value: "EventMeta" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "available" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "displayMap" } },
								{ kind: "Field", name: { kind: "Name", value: "doorTime" } },
								{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "image" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												alias: { kind: "Name", value: "urlPreview" },
												name: { kind: "Name", value: "url" },
												arguments: [
													{
														kind: "Argument",
														name: { kind: "Name", value: "width" },
														value: { kind: "IntValue", value: "1024" },
													},
												],
											},
											{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "kulturpassUrl" } },
								{ kind: "Field", name: { kind: "Name", value: "localOrganizer" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "overlayDetailImage" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "owningCompanyAddress" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "FormattedAddress" },
											},
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "presaleAt" } },
								{ kind: "Field", name: { kind: "Name", value: "price" } },
								{ kind: "Field", name: { kind: "Name", value: "seatmapUrl" } },
								{ kind: "Field", name: { kind: "Name", value: "soldOutMessage" } },
								{ kind: "Field", name: { kind: "Name", value: "stageTime" } },
								{ kind: "Field", name: { kind: "Name", value: "tourOrganizer" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venue" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "name" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "city" } },
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "FormattedAddress" },
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "tours" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "first" },
											value: { kind: "IntValue", value: "1" },
										},
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "nodes" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "slug" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventMeta" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "presaleAt" } },
					{ kind: "Field", name: { kind: "Name", value: "price" } },
					{ kind: "Field", name: { kind: "Name", value: "stageTime" } },
					{ kind: "Field", name: { kind: "Name", value: "doorTime" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "localOrganizer" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "soldOutMessage" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "city" } },
											{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
											{ kind: "Field", name: { kind: "Name", value: "address" } },
											{ kind: "Field", name: { kind: "Name", value: "postCode" } },
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									alias: { kind: "Name", value: "urlPreview" },
									name: { kind: "Name", value: "url" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "width" },
											value: { kind: "IntValue", value: "1024" },
										},
									],
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FormattedAddress" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "address" } },
					{ kind: "Field", name: { kind: "Name", value: "address2" } },
					{ kind: "Field", name: { kind: "Name", value: "city" } },
					{ kind: "Field", name: { kind: "Name", value: "company" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "country" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "firstName" } },
					{ kind: "Field", name: { kind: "Name", value: "lastName" } },
					{ kind: "Field", name: { kind: "Name", value: "postCode" } },
				],
			},
		},
	],
} as unknown as DocumentNode<EventDetailsQuery, EventDetailsQueryVariables>;
export const EventMetaDetailsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "EventMetaDetails" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "event" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
								{ kind: "Field", name: { kind: "Name", value: "stageTime" } },
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "image" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												alias: { kind: "Name", value: "urlPreview" },
												name: { kind: "Name", value: "url" },
												arguments: [
													{
														kind: "Argument",
														name: { kind: "Name", value: "width" },
														value: { kind: "IntValue", value: "1024" },
													},
												],
											},
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venue" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "name" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "city" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EventMetaDetailsQuery, EventMetaDetailsQueryVariables>;
export const EventCalendarDetailsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "EventCalendarDetails" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "event" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "doorTime" } },
								{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
								{ kind: "Field", name: { kind: "Name", value: "stageTime" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venue" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "name" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "address" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "city" } },
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "FormattedAddress" },
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "FormattedAddress" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "address" } },
					{ kind: "Field", name: { kind: "Name", value: "address2" } },
					{ kind: "Field", name: { kind: "Name", value: "city" } },
					{ kind: "Field", name: { kind: "Name", value: "company" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "country" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "firstName" } },
					{ kind: "Field", name: { kind: "Name", value: "lastName" } },
					{ kind: "Field", name: { kind: "Name", value: "postCode" } },
				],
			},
		},
	],
} as unknown as DocumentNode<EventCalendarDetailsQuery, EventCalendarDetailsQueryVariables>;
export const EventVariantsPageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "EventVariantsPage" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "event" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariants" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "after" },
											value: { kind: "Variable", name: { kind: "Name", value: "after" } },
										},
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "nodes" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "__typename" } },
														{
															kind: "InlineFragment",
															typeCondition: {
																kind: "NamedType",
																name: { kind: "Name", value: "EventProductVariant" },
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: { kind: "Name", value: "EventVariant" },
																	},
																],
															},
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "pageInfo" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
														{ kind: "Field", name: { kind: "Name", value: "endCursor" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventVariantSection" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VenueSection" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventVariant" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EventProductVariant" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "active" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "physical" } },
					{ kind: "Field", name: { kind: "Name", value: "price" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "pricingCategory" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "availableSections" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "pageInfo" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "endCursor" } },
											{ kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "EventVariantSection" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EventVariantsPageQuery, EventVariantsPageQueryVariables>;
export const EventVariantSectionPageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "EventVariantSectionPage" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "variantId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "event" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "id" },
											value: { kind: "Variable", name: { kind: "Name", value: "variantId" } },
										},
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "EventProductVariant" },
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "availableSections" },
															arguments: [
																{
																	kind: "Argument",
																	name: { kind: "Name", value: "after" },
																	value: {
																		kind: "Variable",
																		name: { kind: "Name", value: "after" },
																	},
																},
															],
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "pageInfo" },
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "endCursor",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "hasNextPage",
																					},
																				},
																			],
																		},
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "nodes" },
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "FragmentSpread",
																					name: {
																						kind: "Name",
																						value: "EventVariantSection",
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventVariantSection" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VenueSection" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
				],
			},
		},
	],
} as unknown as DocumentNode<EventVariantSectionPageQuery, EventVariantSectionPageQueryVariables>;
export const ProductDetailsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "ProductDetails" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "FragmentSpread", name: { kind: "Name", value: "ProductMeta" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "available" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "price" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "countryCode" },
											value: { kind: "StringValue", value: "de", block: false },
										},
									],
								},
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "shippingRequirements" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "additionalInformation" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "description" } },
														{ kind: "Field", name: { kind: "Name", value: "title" } },
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "details" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "description" } },
														{ kind: "Field", name: { kind: "Name", value: "title" } },
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "lowStock" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "originalPrice" },
												arguments: [
													{
														kind: "Argument",
														name: { kind: "Name", value: "countryCode" },
														value: { kind: "StringValue", value: "de", block: false },
													},
												],
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductMeta" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "images" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "KSImagesWithTags" },
											},
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "originalPrice" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "price" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "productType" } },
					{ kind: "Field", name: { kind: "Name", value: "color" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "material" } },
					{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "lowStock" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
				],
			},
		},
	],
} as unknown as DocumentNode<ProductDetailsQuery, ProductDetailsQueryVariables>;
export const ProductDetailVariantPageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "ProductDetailVariantPage" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariants" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "after" },
											value: { kind: "Variable", name: { kind: "Name", value: "after" } },
										},
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "nodes" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "ProductDetailVariant" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "pageInfo" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
														{ kind: "Field", name: { kind: "Name", value: "endCursor" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductDetailVariant" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ProductVariant" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "active" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "productId" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "price" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ProductDetailVariantPageQuery, ProductDetailVariantPageQueryVariables>;
export const ProductDetailImagePageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "ProductDetailImagePage" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												arguments: [
													{
														kind: "Argument",
														name: { kind: "Name", value: "after" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "after" },
														},
													},
												],
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "ProductDetailImage",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "pageInfo" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "hasNextPage" },
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "endCursor" },
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductDetailImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImagesWithTags" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ProductDetailImagePageQuery, ProductDetailImagePageQueryVariables>;
export const TourDetailsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "TourDetails" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "tourId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "category" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "tourId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Tour" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "ticketImage" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "subtitle" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
	],
} as unknown as DocumentNode<TourDetailsQuery, TourDetailsQueryVariables>;
export const KrasserstoffHomePageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "KrasserstoffHomePage" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "categories" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "types" },
								value: { kind: "EnumValue", value: "ARTIST" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "FragmentSpread", name: { kind: "Name", value: "HomeArtists" } },
											{ kind: "Field", name: { kind: "Name", value: "mainCategory" } },
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "news" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "4" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "FragmentSpread", name: { kind: "Name", value: "HomeNews" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "HomeArtists" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CategoryInterface" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "squareImage" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "HomeNews" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "News" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", alias: { kind: "Name", value: "bgColor" }, name: { kind: "Name", value: "url" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } }],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "text" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
				],
			},
		},
	],
} as unknown as DocumentNode<KrasserstoffHomePageQuery, KrasserstoffHomePageQueryVariables>;
export const SeatmapMetadataDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "SeatmapMetadata" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "eventSlug" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "event" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "eventSlug" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SeatmapMetadataQuery, SeatmapMetadataQueryVariables>;
export const SeatmapSvgDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "SeatmapSvg" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "eventSlug" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "event" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "eventSlug" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "availableSeatIds" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariants" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "nodes" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "__typename" } },
														{ kind: "Field", name: { kind: "Name", value: "available" } },
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{
															kind: "InlineFragment",
															typeCondition: {
																kind: "NamedType",
																name: { kind: "Name", value: "EventProductVariant" },
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "availableSectionIds",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "pricingCategory",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: { kind: "Name", value: "id" },
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "name",
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "seatmapUrl" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SeatmapSvgQuery, SeatmapSvgQueryVariables>;
export const SeatmapPageDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "SeatmapPage" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "eventSlug" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "event" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "eventSlug" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "displayMap" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SeatmapPageQuery, SeatmapPageQueryVariables>;
export const BandshopNewsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "BandshopNews" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "news" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "categories" },
								value: {
									kind: "ListValue",
									values: [{ kind: "StringValue", value: "regular", block: false }],
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "5" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "url" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
	],
} as unknown as DocumentNode<BandshopNewsQuery, BandshopNewsQueryVariables>;
export const BandshopNewsTitleDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "BandshopNewsTitle" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "news" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "categories" },
								value: {
									kind: "ListValue",
									values: [{ kind: "StringValue", value: "hot", block: false }],
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "first" },
								value: { kind: "IntValue", value: "1" },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } },
											{ kind: "Field", name: { kind: "Name", value: "url" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<BandshopNewsTitleQuery, BandshopNewsTitleQueryVariables>;
export const CategoryCardDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "CategoryCard" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "category" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Category" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "merchProducts" },
												arguments: [
													{
														kind: "Argument",
														name: { kind: "Name", value: "after" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "after" },
														},
													},
													{
														kind: "Argument",
														name: { kind: "Name", value: "sortDirection" },
														value: { kind: "EnumValue", value: "DESCENDING" },
													},
												],
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "id" },
																	},
																	{
																		kind: "FragmentSpread",
																		name: { kind: "Name", value: "ProductCard" },
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "pageInfo" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "endCursor" },
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "hasNextPage" },
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductMeta" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "images" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "KSImagesWithTags" },
											},
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "originalPrice" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "price" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "productType" } },
					{ kind: "Field", name: { kind: "Name", value: "color" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "material" } },
					{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "lowStock" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ProductCard" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "ProductMeta" } },
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "images" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "KSImagesWithTags" },
											},
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "originalPrice" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "price" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "countryCode" },
								value: { kind: "StringValue", value: "de", block: false },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "productType" } },
					{ kind: "Field", name: { kind: "Name", value: "color" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
				],
			},
		},
	],
} as unknown as DocumentNode<CategoryCardQuery, CategoryCardQueryVariables>;
export const CategoryEventsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "CategoryEvents" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "tourId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "category" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "tourId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "events" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "after" },
											value: { kind: "Variable", name: { kind: "Name", value: "after" } },
										},
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "nodes" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "EventListEvent" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "pageInfo" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "endCursor" } },
														{ kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventMeta" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "presaleAt" } },
					{ kind: "Field", name: { kind: "Name", value: "price" } },
					{ kind: "Field", name: { kind: "Name", value: "stageTime" } },
					{ kind: "Field", name: { kind: "Name", value: "doorTime" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "localOrganizer" } },
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "soldOutMessage" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "city" } },
											{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
											{ kind: "Field", name: { kind: "Name", value: "address" } },
											{ kind: "Field", name: { kind: "Name", value: "postCode" } },
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "image" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									alias: { kind: "Name", value: "urlPreview" },
									name: { kind: "Name", value: "url" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "width" },
											value: { kind: "IntValue", value: "1024" },
										},
									],
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "EventListEvent" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "EventMeta" } },
					{ kind: "Field", name: { kind: "Name", value: "artist" } },
					{ kind: "Field", name: { kind: "Name", value: "available" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
					{ kind: "Field", name: { kind: "Name", value: "happeningUntil" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "meta" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "presaleAt" } },
					{ kind: "Field", name: { kind: "Name", value: "price" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariants" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "price" } },
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "slug" } },
					{ kind: "Field", name: { kind: "Name", value: "soldOutMessage" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "venue" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "address" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "city" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CategoryEventsQuery, CategoryEventsQueryVariables>;
export const EventListDetailsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "EventListDetails" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "tourId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "category" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "tourId" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Tour" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "ticketImage" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
										],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "subtitle" } },
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
	],
} as unknown as DocumentNode<EventListDetailsQuery, EventListDetailsQueryVariables>;
export const BandshopFilterDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "BandshopFilter" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "categories" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "nodes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "name" } },
											{ kind: "Field", name: { kind: "Name", value: "slug" } },
											{ kind: "Field", name: { kind: "Name", value: "mainCategory" } },
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "Category" },
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "merchProducts" },
															arguments: [
																{
																	kind: "Argument",
																	name: { kind: "Name", value: "first" },
																	value: { kind: "IntValue", value: "1" },
																},
															],
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "nodes" },
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: { kind: "Name", value: "id" },
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "Tour" },
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "events" },
															arguments: [
																{
																	kind: "Argument",
																	name: { kind: "Name", value: "first" },
																	value: { kind: "IntValue", value: "1" },
																},
															],
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "nodes" },
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: { kind: "Name", value: "id" },
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<BandshopFilterQuery, BandshopFilterQueryVariables>;
export const SessionExpiresAtDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "SessionExpiresAt" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "session" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "expiresAt" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SessionExpiresAtQuery, SessionExpiresAtQueryVariables>;
export const HeartbeatDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "Heartbeat" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "heartbeat" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: { kind: "ObjectValue", fields: [] },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "clientMutationId" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<HeartbeatMutation, HeartbeatMutationVariables>;
export const UseCartDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "UseCart" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "cart" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "UseCartCart" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "NoteList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseDeliveryCountry" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [{ kind: "Field", name: { kind: "Name", value: "countryCode" } }],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseCartCart" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "productVariantId" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItemList" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "NoteList" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "total" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "UseDeliveryCountry" } },
				],
			},
		},
	],
} as unknown as DocumentNode<UseCartQuery, UseCartQueryVariables>;
export const RemoveItemDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "RemoveItem" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "itemId" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "removeItem" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "itemId" },
											value: { kind: "Variable", name: { kind: "Name", value: "itemId" } },
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "cart" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "FragmentSpread", name: { kind: "Name", value: "UseCartCart" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "NoteList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseDeliveryCountry" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [{ kind: "Field", name: { kind: "Name", value: "countryCode" } }],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseCartCart" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "productVariantId" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItemList" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "NoteList" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "total" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "UseDeliveryCountry" } },
				],
			},
		},
	],
} as unknown as DocumentNode<RemoveItemMutation, RemoveItemMutationVariables>;
export const LogActivityDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "LogActivity" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "action" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "details" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "JSON" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "logActivity" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "action" },
											value: { kind: "Variable", name: { kind: "Name", value: "action" } },
										},
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "details" },
											value: { kind: "Variable", name: { kind: "Name", value: "details" } },
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "clientMutationId" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<LogActivityMutation, LogActivityMutationVariables>;
export const AddItemsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "AddItems" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "addItemRequests" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: { kind: "NamedType", name: { kind: "Name", value: "Request" } },
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "addItems" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "requests" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "addItemRequests" },
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "cart" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "FragmentSpread", name: { kind: "Name", value: "UseCartCart" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "NoteList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseDeliveryCountry" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [{ kind: "Field", name: { kind: "Name", value: "countryCode" } }],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseCartCart" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "productVariantId" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItemList" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "NoteList" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "total" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "UseDeliveryCountry" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AddItemsMutation, AddItemsMutationVariables>;
export const ChangeCountryDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "ChangeCountry" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "countryCode" } },
					type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "changeCountry" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "countryCode" },
											value: { kind: "Variable", name: { kind: "Name", value: "countryCode" } },
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "cart" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "FragmentSpread", name: { kind: "Name", value: "UseCartCart" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItem" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "cartLimit" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImage" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "alt" } },
					{ kind: "Field", name: { kind: "Name", value: "height" } },
					{ kind: "Field", name: { kind: "Name", value: "thumbhash" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "url16" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "16" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url32" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "32" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url48" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "48" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url64" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "64" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url96" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "96" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url128" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "128" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url256" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "256" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url384" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "384" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url390" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "390" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url485" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "485" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url834" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "834" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1024" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1280" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1280" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1440" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url1920" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1920" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url2560" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "2560" },
							},
						],
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "url3440" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "3440" },
							},
						],
					},
					{ kind: "Field", name: { kind: "Name", value: "width" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "KSImagesWithTags" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Image" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "KSImage" } },
					{ kind: "Field", name: { kind: "Name", value: "order" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "urlPreview" },
						name: { kind: "Name", value: "url" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "width" },
								value: { kind: "IntValue", value: "1024" },
							},
						],
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "TicketLocationText" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "seat" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "row" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "venueSection" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "section" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "CartItemList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItem" } },
					{ kind: "Field", name: { kind: "Name", value: "bundledItemIds" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "invalidated" } },
					{ kind: "Field", name: { kind: "Name", value: "seatId" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "artist" } },
								{ kind: "Field", name: { kind: "Name", value: "descriptiveName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "meta" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MerchProduct" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "material" } },
											{ kind: "Field", name: { kind: "Name", value: "color" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "images" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "nodes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "KSImagesWithTags",
																		},
																	},
																],
															},
														},
													],
												},
											},
											{ kind: "Field", name: { kind: "Name", value: "productType" } },
										],
									},
								},
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "image" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "KSImage" },
														},
													],
												},
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "venue" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "address" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "city" },
																	},
																],
															},
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "physical" } },
							],
						},
					},
					{ kind: "FragmentSpread", name: { kind: "Name", value: "TicketLocationText" } },
					{ kind: "Field", name: { kind: "Name", value: "total" } },
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "NoteList" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CartItem" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "product" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicShippableFrom" } },
								{
									kind: "InlineFragment",
									typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Event" } },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "happeningFrom" } }],
									},
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "productVariant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
						},
					},
				],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseDeliveryCountry" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [{ kind: "Field", name: { kind: "Name", value: "countryCode" } }],
			},
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "UseCartCart" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "countryCode" } },
					{ kind: "Field", name: { kind: "Name", value: "reference" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "items" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "productVariant" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [{ kind: "Field", name: { kind: "Name", value: "physical" } }],
									},
								},
								{ kind: "Field", name: { kind: "Name", value: "productVariantId" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "CartItemList" } },
								{ kind: "FragmentSpread", name: { kind: "Name", value: "NoteList" } },
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "total" } },
					{ kind: "FragmentSpread", name: { kind: "Name", value: "UseDeliveryCountry" } },
				],
			},
		},
	],
} as unknown as DocumentNode<ChangeCountryMutation, ChangeCountryMutationVariables>;
export const ShopMetaDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "ShopMeta" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "shop" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "meta" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ShopMetaQuery, ShopMetaQueryVariables>;
export const BandshopDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "Bandshop" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "shop" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "defaultLocale" } },
								{ kind: "Field", name: { kind: "Name", value: "domain" } },
								{ kind: "Field", name: { kind: "Name", value: "slug" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<BandshopQuery, BandshopQueryVariables>;
