/* eslint-disable */
import * as types from "./graphql";
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
	"\n\tfragment OrderList on Order {\n\t\tbalance\n\t\tcancelAt\n\t\tdeliveries(first: 100) {\n\t\t\tnodes {\n\t\t\t\taddress {\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t\tid\n\t\t\t\tstatus\n\t\t\t\ttrackingCode\n\t\t\t}\n\t\t}\n\t\tid\n\t\tinvoices(first: 100) {\n\t\t\tnodes {\n\t\t\t\taddress {\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t\tid\n\t\t\t\treference\n\t\t\t}\n\t\t}\n\t\titems {\n\t\t\t...CartItemList\n\t\t\tinvalidated\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t\torderedAt\n\t\tpreferredPaymentMethod {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\treference\n\t\tshop {\n\t\t\tmeta\n\t\t\tname\n\t\t}\n\t\tstatus\n\t\t...StatusHeader\n\t}\n":
		types.OrderListFragmentDoc,
	"\n\tfragment StatusHeader on Order {\n\t\tbalance\n\t\tdeliveries(first: 100) {\n\t\t\tnodes {\n\t\t\t\ttrackingCode\n\t\t\t}\n\t\t}\n\t\titems {\n\t\t\tinvalidated\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t\tmeta\n\t\t\t\tpublicShippableFrom\n\t\t\t}\n\t\t\tproductVariant {\n\t\t\t\tphysical\n\t\t\t}\n\t\t\tticketUrl\n\t\t}\n\t\tpaymentLink\n\t\tstatus\n\t}\n":
		types.StatusHeaderFragmentDoc,
	"\n\tfragment TicketList on Order {\n\t\treference\n\t\titems {\n\t\t\tid\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t\tid\n\t\t\t\t... on Event {\n\t\t\t\t\thappeningFrom\n\t\t\t\t\thappeningUntil\n\t\t\t\t}\n\t\t\t}\n\t\t\t...Event\n\t\t}\n\t}\n":
		types.TicketListFragmentDoc,
	"\n\tfragment CompareTicketLocation on CartItem {\n\t\tseat {\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t\trow\n\t\t\tname\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t}\n":
		types.CompareTicketLocationFragmentDoc,
	"\n\tfragment Event on CartItem {\n\t\tid\n\t\tproduct {\n\t\t\t...EventHeader\n\t\t}\n\t\t...TicketListItem\n\t\t...CompareTicketLocation\n\t\t...TicketCheckboxes\n\t}\n":
		types.EventFragmentDoc,
	"\n\tfragment EventHeader on Event {\n\t\tartist\n\t\thappeningFrom\n\t\tname\n\t\tslug\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t}\n\t\t\tname\n\t\t}\n\t\timage {\n\t\t\t...KSImage\n\t\t}\n\t}\n":
		types.EventHeaderFragmentDoc,
	"\n\tfragment TicketListItem on CartItem {\n\t\tid\n\t\tinvalidated\n\t\t...TicketLocationText\n\t\t...ItemDescription\n\t\tproduct {\n\t\t\tslug\n\t\t\t__typename\n\t\t\t... on Event {\n\t\t\t\tartist\n\t\t\t\tdescriptiveName\n\t\t\t\tvenue {\n\t\t\t\t\taddress {\n\t\t\t\t\t\tcity\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\thappeningFrom\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tphysical\n\t\t}\n\t\tticketUrl\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t}\n":
		types.TicketListItemFragmentDoc,
	"\n\tfragment ItemDescription on CartItem {\n\t\tseat {\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t\trow\n\t\t\tname\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t\tvalidationCode\n\t\tinvalidated\n\t\tresaleOffer {\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t\t...TicketLocationText\n\t}\n":
		types.ItemDescriptionFragmentDoc,
	"\n\tquery AccountPage {\n\t\tsession {\n\t\t\tuser {\n\t\t\t\temail\n\t\t\t\tfirstName\n\t\t\t\tid\n\t\t\t\tlastName\n\t\t\t}\n\t\t\tvalidUntil\n\t\t}\n\t\torders {\n\t\t\tnodes {\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\t...OrderList\n\t\t\t\tstatus\n\t\t\t\t...TicketList\n\t\t\t}\n\t\t}\n\t}\n":
		types.AccountPageDocument,
	"\n\tmutation CancelResale($input: CancelResaleInput!) {\n\t\tcancelResale(input: $input) {\n\t\t\tclientMutationId\n\t\t\titems {\n\t\t\t\t...FieldsUpdatedByMutation\n\t\t\t}\n\t\t}\n\t}\n":
		types.CancelResaleDocument,
	"\n\tmutation CreateResale($input: OfferForResaleInput!) {\n\t\tofferForResale(input: $input) {\n\t\t\tclientMutationId\n\t\t\titems {\n\t\t\t\t...FieldsUpdatedByMutation\n\t\t\t}\n\t\t}\n\t}\n":
		types.CreateResaleDocument,
	"\n\tfragment FieldsUpdatedByMutation on CartItem {\n\t\tid\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tcode\n\t\t\tid\n\t\t\tprice\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t}\n":
		types.FieldsUpdatedByMutationFragmentDoc,
	"\n\tfragment TicketCheckboxes on CartItem {\n\t\tid\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tcode\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\t...ItemDescription\n\t}\n":
		types.TicketCheckboxesFragmentDoc,
	"\n\tmutation FinishLogin($code: String!, $email: String!) {\n\t\tfinishCodeLogin(input: { code: $code, email: $email }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n":
		types.FinishLoginDocument,
	"\n\tmutation RequestLogin($email: String!, $redirectUrl: String!) {\n\t\trequestCodeLogin(input: { email: $email, redirectUrl: $redirectUrl }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n":
		types.RequestLoginDocument,
	"\n\t\t\tquery OrderPageMetadata($id: ID!) {\n\t\t\t\torder(id: $id) {\n\t\t\t\t\treference\n\t\t\t\t}\n\t\t\t}\n\t\t":
		types.OrderPageMetadataDocument,
	"\n\t\t\tquery OrderPage($id: ID!) {\n\t\t\t\torder(id: $id) {\n\t\t\t\t\titems {\n\t\t\t\t\t\tid\n\t\t\t\t\t}\n\t\t\t\t\t...OrderList\n\t\t\t\t\tstatus\n\t\t\t\t\t...TicketList\n\t\t\t\t}\n\t\t\t}\n\t\t":
		types.OrderPageDocument,
	"\n\t\tquery BandshopLayoutMetadata {\n\t\t\tshop {\n\t\t\t\tmeta\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t":
		types.BandshopLayoutMetadataDocument,
	"\n\tquery BandshopPage {\n\t\tcategories {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tmainCategory\n\t\t\t\tslug\n\t\t\t\t__typename\n\t\t\t}\n\t\t}\n\t}\n":
		types.BandshopPageDocument,
	"\n\tmutation ConfirmOrderPayment($orderId: ID!) {\n\t\tconfirmOrderPayment(input: { orderId: $orderId }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n":
		types.ConfirmOrderPaymentDocument,
	"\n\tquery Payment($ids: [ID!]!, $countryCode: String!) {\n\t\tpaymentMethods(ids: $ids, countryCode: $countryCode) {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n":
		types.PaymentDocument,
	"\n\tquery Shipping($ids: [ID!]!, $countryCode: String!) {\n\t\tshippingMethods(ids: $ids, countryCode: $countryCode) {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tprice\n\t\t\t}\n\t\t}\n\t}\n":
		types.ShippingDocument,
	"\n\tfragment NoteList on CartItem {\n\t\tproduct {\n\t\t\t__typename\n\t\t\tpublicShippableFrom\n\t\t\t... on Event {\n\t\t\t\thappeningFrom\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tphysical\n\t\t}\n\t}\n":
		types.NoteListFragmentDoc,
	"\n\tmutation PlaceOrder($input: PlaceOrderInput!) {\n\t\tplaceOrder(input: $input) {\n\t\t\tcart {\n\t\t\t\tid\n\t\t\t\treference\n\t\t\t}\n\t\t}\n\t}\n":
		types.PlaceOrderDocument,
	"\n\tquery EventDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t...EventMeta\n\t\t\t__typename\n\t\t\tartist\n\t\t\tavailable\n\t\t\tcartLimit\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\tdisplayMap\n\t\t\tdoorTime\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tid\n\t\t\timage {\n\t\t\t\turlPreview: url(width: 1024)\n\t\t\t\t...KSImage\n\t\t\t}\n\t\t\tkulturpassUrl\n\t\t\tlocalOrganizer\n\t\t\tmeta\n\t\t\tname\n\t\t\toverlayDetailImage {\n\t\t\t\t...KSImage\n\t\t\t}\n\t\t\towningCompanyAddress {\n\t\t\t\t...FormattedAddress\n\t\t\t}\n\t\t\tpresaleAt\n\t\t\tprice\n\t\t\tseatmapUrl\n\t\t\tsoldOutMessage\n\t\t\tstageTime\n\t\t\ttourOrganizer\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t}\n\t\t\ttours(first: 1) {\n\t\t\t\tnodes {\n\t\t\t\t\tslug\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.EventDetailsDocument,
	"\n\tquery EventMetaDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tstageTime\n\t\t\tslug\n\t\t\timage {\n\t\t\t\turlPreview: url(width: 1024)\n\t\t\t}\n\t\t\tname\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.EventMetaDetailsDocument,
	"\n\tquery EventCalendarDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tname\n\t\t\tdoorTime\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tstageTime\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.EventCalendarDetailsDocument,
	"\n\tfragment EventVariant on EventProductVariant {\n\t\t__typename\n\t\tactive\n\t\tavailable\n\t\tdescription\n\t\tid\n\t\tphysical\n\t\tprice\n\t\tpricingCategory {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tavailableSections {\n\t\t\tpageInfo {\n\t\t\t\tendCursor\n\t\t\t\thasNextPage\n\t\t\t}\n\t\t\tnodes {\n\t\t\t\t...EventVariantSection\n\t\t\t}\n\t\t}\n\t}\n":
		types.EventVariantFragmentDoc,
	"\n\tquery EventVariantsPage($eventId: ID!, $after: String) {\n\t\tevent(id: $eventId) {\n\t\t\tproductVariants(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t__typename\n\t\t\t\t\t... on EventProductVariant {\n\t\t\t\t\t\t...EventVariant\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\thasNextPage\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.EventVariantsPageDocument,
	"\n\tfragment EventVariantSection on VenueSection {\n\t\tid\n\t\tname\n\t}\n": types.EventVariantSectionFragmentDoc,
	"\n\tquery EventVariantSectionPage($eventId: ID!, $variantId: ID!, $after: String) {\n\t\tevent(id: $eventId) {\n\t\t\tproductVariant(id: $variantId) {\n\t\t\t\t__typename\n\t\t\t\t... on EventProductVariant {\n\t\t\t\t\tavailableSections(after: $after) {\n\t\t\t\t\t\tpageInfo {\n\t\t\t\t\t\t\tendCursor\n\t\t\t\t\t\t\thasNextPage\n\t\t\t\t\t\t}\n\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t...EventVariantSection\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.EventVariantSectionPageDocument,
	'\n\tquery ProductDetails($productId: ID!) {\n\t\tproduct(id: $productId) {\n\t\t\t...ProductMeta\n\t\t\t__typename\n\t\t\tartist\n\t\t\tavailable\n\t\t\tcartLimit\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\tid\n\t\t\tslug\n\t\t\tmeta\n\t\t\tname\n\t\t\tprice(countryCode: "de")\n\t\t\tpublicShippableFrom\n\t\t\tshippingRequirements\n\t\t\t... on MerchProduct {\n\t\t\t\tmaterial\n\t\t\t\tadditionalInformation {\n\t\t\t\t\tdescription\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t\tcolor\n\t\t\t\tdetails {\n\t\t\t\t\tdescription\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t\tlowStock\n\t\t\t\toriginalPrice(countryCode: "de")\n\t\t\t\tproductType\n\t\t\t}\n\t\t}\n\t}\n':
		types.ProductDetailsDocument,
	'\n\tfragment ProductDetailVariant on ProductVariant {\n\t\tactive\n\t\tavailable\n\t\tid\n\t\tdescription\n\t\tproductId\n\t\torder\n\t\tprice(countryCode: "de")\n\t}\n':
		types.ProductDetailVariantFragmentDoc,
	"\n\tquery ProductDetailVariantPage($productId: ID!, $after: String) {\n\t\tproduct(id: $productId) {\n\t\t\tid\n\t\t\tproductVariants(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t...ProductDetailVariant\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\thasNextPage\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.ProductDetailVariantPageDocument,
	"\n\tfragment ProductDetailImage on Image {\n\t\t...KSImagesWithTags\n\t\ttags\n\t\turlPreview: url(width: 1024)\n\t}\n":
		types.ProductDetailImageFragmentDoc,
	"\n\tquery ProductDetailImagePage($productId: ID!, $after: String) {\n\t\tproduct(id: $productId) {\n\t\t\t__typename\n\t\t\t... on MerchProduct {\n\t\t\t\timages(after: $after) {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\t...ProductDetailImage\n\t\t\t\t\t}\n\t\t\t\t\tpageInfo {\n\t\t\t\t\t\thasNextPage\n\t\t\t\t\t\tendCursor\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.ProductDetailImagePageDocument,
	"\n\tquery TourDetails($tourId: ID!) {\n\t\tcategory(id: $tourId) {\n\t\t\t__typename\n\t\t\tdescription\n\t\t\tid\n\t\t\tname\n\t\t\t... on Tour {\n\t\t\t\tticketImage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t\tsubtitle\n\t\t}\n\t}\n":
		types.TourDetailsDocument,
	"\n\tfragment HomeNews on News {\n\t\tbgColor: url\n\t\timage {\n\t\t\t...KSImage\n\t\t}\n\t\ttext\n\t\ttitle\n\t}\n":
		types.HomeNewsFragmentDoc,
	"\n\tfragment HomeArtists on CategoryInterface {\n\t\tname\n\t\tslug\n\t\tsquareImage {\n\t\t\t...KSImage\n\t\t}\n\t}\n":
		types.HomeArtistsFragmentDoc,
	"\n\t\tquery KrasserstoffHomePage {\n\t\t\tcategories(types: ARTIST) {\n\t\t\t\tnodes {\n\t\t\t\t\t...HomeArtists\n\t\t\t\t\tmainCategory\n\t\t\t\t}\n\t\t\t}\n\t\t\tnews(first: 4) {\n\t\t\t\tnodes {\n\t\t\t\t\t...HomeNews\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t":
		types.KrasserstoffHomePageDocument,
	"\n\t\t\tquery SeatmapMetadata($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tdescriptiveName\n\t\t\t\t}\n\t\t\t}\n\t\t":
		types.SeatmapMetadataDocument,
	"\n\t\t\tquery SeatmapSvg($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tavailableSeatIds\n\t\t\t\t\tdescriptiveName\n\t\t\t\t\tproductVariants {\n\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t__typename\n\t\t\t\t\t\t\tavailable\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t... on EventProductVariant {\n\t\t\t\t\t\t\t\tavailableSectionIds\n\t\t\t\t\t\t\t\tpricingCategory {\n\t\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\tseatmapUrl\n\t\t\t\t}\n\t\t\t}\n\t\t":
		types.SeatmapSvgDocument,
	"\n\t\t\tquery SeatmapPage($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tdescriptiveName\n\t\t\t\t\tdisplayMap\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t":
		types.SeatmapPageDocument,
	"\n\tfragment FormattedAddress on Address {\n\t\taddress\n\t\taddress2\n\t\tcity\n\t\tcompany\n\t\tcountry {\n\t\t\tcountryCode\n\t\t\tname\n\t\t}\n\t\tfirstName\n\t\tlastName\n\t\tpostCode\n\t}\n":
		types.FormattedAddressFragmentDoc,
	"\n\tfragment TicketLocationText on CartItem {\n\t\tseat {\n\t\t\tname\n\t\t\trow\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t}\n":
		types.TicketLocationTextFragmentDoc,
	'\n\tquery BandshopNews {\n\t\tnews(categories: ["regular"], first: 5) {\n\t\t\tnodes {\n\t\t\t\turl\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\ttext\n\t\t\t\timage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n':
		types.BandshopNewsDocument,
	'\n\tquery BandshopNewsTitle {\n\t\tnews(categories: ["hot"], first: 1) {\n\t\t\tnodes {\n\t\t\t\ttitle\n\t\t\t\ttext\n\t\t\t\turl\n\t\t\t}\n\t\t}\n\t}\n':
		types.BandshopNewsTitleDocument,
	"\n\tquery CategoryCard($id: ID!, $after: String!) {\n\t\tcategory(id: $id) {\n\t\t\t__typename\n\t\t\tname\n\t\t\t... on Category {\n\t\t\t\tmerchProducts(after: $after, sortDirection: DESCENDING) {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\tid\n\t\t\t\t\t\t...ProductCard\n\t\t\t\t\t}\n\t\t\t\t\tpageInfo {\n\t\t\t\t\t\tendCursor\n\t\t\t\t\t\thasNextPage\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.CategoryCardDocument,
	"\n\tquery CategoryEvents($tourId: ID!, $after: String!) {\n\t\tcategory(id: $tourId) {\n\t\t\tevents(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t...EventListEvent\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\tendCursor\n\t\t\t\t\thasNextPage\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.CategoryEventsDocument,
	"\n\tquery EventListDetails($tourId: ID!) {\n\t\tcategory(id: $tourId) {\n\t\t\t__typename\n\t\t\tid\n\t\t\tname\n\t\t\t... on Tour {\n\t\t\t\tticketImage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t\tsubtitle\n\t\t}\n\t}\n":
		types.EventListDetailsDocument,
	"\n\tfragment EventListEvent on Event {\n\t\t...EventMeta\n\t\tartist\n\t\tavailable\n\t\thappeningFrom\n\t\thappeningUntil\n\t\tid\n\t\tmeta\n\t\tname\n\t\tpresaleAt\n\t\tprice\n\t\tproductVariants {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tprice\n\t\t\t}\n\t\t}\n\t\tslug\n\t\tsoldOutMessage\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t}\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n":
		types.EventListEventFragmentDoc,
	"\n\t\tquery BandshopFilter {\n\t\t\tcategories {\n\t\t\t\tnodes {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tslug\n\t\t\t\t\tmainCategory\n\t\t\t\t\t__typename\n\t\t\t\t\t... on Category {\n\t\t\t\t\t\t# query first product to check if category has products\n\t\t\t\t\t\tmerchProducts(first: 1) {\n\t\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\t... on Tour {\n\t\t\t\t\t\t# query first event to check if category has events\n\t\t\t\t\t\tevents(first: 1) {\n\t\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t":
		types.BandshopFilterDocument,
	"\n\tfragment KSImage on Image {\n\t\talt\n\t\theight\n\t\tthumbhash\n\t\turl16: url(width: 16)\n\t\turl32: url(width: 32)\n\t\turl48: url(width: 48)\n\t\turl64: url(width: 64)\n\t\turl96: url(width: 96)\n\t\t# rule doesn't support natural sorting\n\t\t# eslint-disable-next-line @graphql-eslint/alphabetize\n\t\turl128: url(width: 128)\n\t\turl256: url(width: 256)\n\t\turl384: url(width: 384)\n\t\turl390: url(width: 390)\n\t\turl485: url(width: 485)\n\t\turl834: url(width: 834)\n\t\t# eslint-disable-next-line @graphql-eslint/alphabetize\n\t\turl1024: url(width: 1024)\n\t\turl1280: url(width: 1280)\n\t\turl1440: url(width: 1440)\n\t\turl1920: url(width: 1920)\n\t\turl2560: url(width: 2560)\n\t\turl3440: url(width: 3440)\n\t\twidth\n\t}\n":
		types.KsImageFragmentDoc,
	'\n\tfragment ProductCard on MerchProduct {\n\t\t...ProductMeta\n\t\tartist\n\t\tid\n\t\timages {\n\t\t\tnodes {\n\t\t\t\t...KSImagesWithTags\n\t\t\t}\n\t\t}\n\t\tname\n\t\tmeta\n\t\toriginalPrice(countryCode: "de")\n\t\tprice(countryCode: "de")\n\t\tproductType\n\t\tcolor\n\t\tslug\n\t}\n':
		types.ProductCardFragmentDoc,
	"\n\tfragment CartItemList on CartItem {\n\t\t...CartItem\n\t\tbundledItemIds\n\t\tid\n\t\tinvalidated\n\t\tseatId\n\t\tproduct {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tdescriptiveName\n\t\t\tid\n\t\t\tmeta\n\t\t\tname\n\t\t\t... on MerchProduct {\n\t\t\t\tmaterial\n\t\t\t\tcolor\n\t\t\t\timages {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\t...KSImagesWithTags\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tproductType\n\t\t\t}\n\t\t\t... on Event {\n\t\t\t\thappeningFrom\n\t\t\t\timage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t\tvenue {\n\t\t\t\t\taddress {\n\t\t\t\t\t\tcity\n\t\t\t\t\t}\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tdescription\n\t\t\tid\n\t\t\tphysical\n\t\t}\n\t\t...TicketLocationText\n\t\ttotal\n\t}\n":
		types.CartItemListFragmentDoc,
	"\n\tfragment CartItem on CartItem {\n\t\tid\n\t\tinvalidated\n\t\tproduct {\n\t\t\tslug\n\t\t\tartist\n\t\t\tcartLimit\n\t\t\tdescriptiveName\n\t\t\tpublicShippableFrom\n\t\t\t__typename\n\t\t}\n\t\tproductVariant {\n\t\t\tid\n\t\t}\n\t}\n":
		types.CartItemFragmentDoc,
	"\n\tfragment EventMeta on Event {\n\t\tartist\n\t\tavailable\n\t\thappeningFrom\n\t\thappeningUntil\n\t\tmeta\n\t\tname\n\t\tpresaleAt\n\t\tprice\n\t\tstageTime\n\t\tdoorTime\n\t\tdescription\n\t\tlocalOrganizer\n\t\tslug\n\t\tsoldOutMessage\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\taddress\n\t\t\t\tpostCode\n\t\t\t}\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\timage {\n\t\t\turlPreview: url(width: 1024)\n\t\t}\n\t}\n":
		types.EventMetaFragmentDoc,
	'\n\tfragment ProductMeta on MerchProduct {\n\t\tartist\n\t\tid\n\t\timages {\n\t\t\tnodes {\n\t\t\t\t...KSImagesWithTags\n\t\t\t}\n\t\t}\n\t\tname\n\t\tmeta\n\t\toriginalPrice(countryCode: "de")\n\t\tprice(countryCode: "de")\n\t\tproductType\n\t\tcolor\n\t\tslug\n\t\tmaterial\n\t\tpublicShippableFrom\n\t\tlowStock\n\t\tavailable\n\t}\n':
		types.ProductMetaFragmentDoc,
	"\n\tquery SessionExpiresAt {\n\t\tsession {\n\t\t\tid\n\t\t\texpiresAt\n\t\t}\n\t}\n":
		types.SessionExpiresAtDocument,
	"\n\tmutation Heartbeat {\n\t\theartbeat(input: {}) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n":
		types.HeartbeatDocument,
	"\n\tfragment UseCartCart on Cart {\n\t\tid # id is needed for apollo to keep track of the cart object, don't remove it!\n\t\tcountryCode\n\t\treference\n\t\titems {\n\t\t\tproductVariant {\n\t\t\t\tphysical\n\t\t\t}\n\t\t\tproductVariantId\n\t\t\t...CartItemList\n\t\t\t...NoteList\n\t\t}\n\t\ttotal\n\t\t...UseDeliveryCountry\n\t}\n":
		types.UseCartCartFragmentDoc,
	"\n\tfragment UseDeliveryCountry on Cart {\n\t\tcountryCode\n\t}\n": types.UseDeliveryCountryFragmentDoc,
	"\n\tquery UseCart {\n\t\tcart {\n\t\t\t...UseCartCart\n\t\t}\n\t}\n": types.UseCartDocument,
	"\n\tmutation RemoveItem($itemId: ID!) {\n\t\tremoveItem(input: { itemId: $itemId }) {\n\t\t\tcart {\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n":
		types.RemoveItemDocument,
	"\n\tmutation LogActivity($action: String!, $details: JSON!) {\n\t\tlogActivity(input: { action: $action, details: $details }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n":
		types.LogActivityDocument,
	'\n\tmutation AddItems($addItemRequests: [Request!]!) {\n\t\t# (input: {requests: [{productVariantId: "c729274f-f6f6-47b0-9789-f1b5c03dd220", amount: 1}]})\n\t\taddItems(input: { requests: $addItemRequests }) {\n\t\t\tcart {\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n':
		types.AddItemsDocument,
	"\n\tmutation ChangeCountry($countryCode: ID!) {\n\t\tchangeCountry(input: { countryCode: $countryCode }) {\n\t\t\tcart {\n\t\t\t\t# we need to refetch the whole cart because the prices can change when the country changes\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n":
		types.ChangeCountryDocument,
	"\n\tfragment KSImagesWithTags on Image {\n\t\t...KSImage\n\t\torder\n\t\ttags\n\t\turlPreview: url(width: 1024)\n\t}\n":
		types.KsImagesWithTagsFragmentDoc,
	"\n\t\tquery ShopMeta {\n\t\t\tshop {\n\t\t\t\tmeta\n\t\t\t}\n\t\t}\n\t": types.ShopMetaDocument,
	"\n\tquery Bandshop {\n\t\tshop {\n\t\t\tdefaultLocale\n\t\t\tdomain\n\t\t\tslug\n\t\t\tname\n\t\t}\n\t}\n":
		types.BandshopDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment OrderList on Order {\n\t\tbalance\n\t\tcancelAt\n\t\tdeliveries(first: 100) {\n\t\t\tnodes {\n\t\t\t\taddress {\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t\tid\n\t\t\t\tstatus\n\t\t\t\ttrackingCode\n\t\t\t}\n\t\t}\n\t\tid\n\t\tinvoices(first: 100) {\n\t\t\tnodes {\n\t\t\t\taddress {\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t\tid\n\t\t\t\treference\n\t\t\t}\n\t\t}\n\t\titems {\n\t\t\t...CartItemList\n\t\t\tinvalidated\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t\torderedAt\n\t\tpreferredPaymentMethod {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\treference\n\t\tshop {\n\t\t\tmeta\n\t\t\tname\n\t\t}\n\t\tstatus\n\t\t...StatusHeader\n\t}\n"
): (typeof documents)["\n\tfragment OrderList on Order {\n\t\tbalance\n\t\tcancelAt\n\t\tdeliveries(first: 100) {\n\t\t\tnodes {\n\t\t\t\taddress {\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t\tid\n\t\t\t\tstatus\n\t\t\t\ttrackingCode\n\t\t\t}\n\t\t}\n\t\tid\n\t\tinvoices(first: 100) {\n\t\t\tnodes {\n\t\t\t\taddress {\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t\tid\n\t\t\t\treference\n\t\t\t}\n\t\t}\n\t\titems {\n\t\t\t...CartItemList\n\t\t\tinvalidated\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t\torderedAt\n\t\tpreferredPaymentMethod {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\treference\n\t\tshop {\n\t\t\tmeta\n\t\t\tname\n\t\t}\n\t\tstatus\n\t\t...StatusHeader\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment StatusHeader on Order {\n\t\tbalance\n\t\tdeliveries(first: 100) {\n\t\t\tnodes {\n\t\t\t\ttrackingCode\n\t\t\t}\n\t\t}\n\t\titems {\n\t\t\tinvalidated\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t\tmeta\n\t\t\t\tpublicShippableFrom\n\t\t\t}\n\t\t\tproductVariant {\n\t\t\t\tphysical\n\t\t\t}\n\t\t\tticketUrl\n\t\t}\n\t\tpaymentLink\n\t\tstatus\n\t}\n"
): (typeof documents)["\n\tfragment StatusHeader on Order {\n\t\tbalance\n\t\tdeliveries(first: 100) {\n\t\t\tnodes {\n\t\t\t\ttrackingCode\n\t\t\t}\n\t\t}\n\t\titems {\n\t\t\tinvalidated\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t\tmeta\n\t\t\t\tpublicShippableFrom\n\t\t\t}\n\t\t\tproductVariant {\n\t\t\t\tphysical\n\t\t\t}\n\t\t\tticketUrl\n\t\t}\n\t\tpaymentLink\n\t\tstatus\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment TicketList on Order {\n\t\treference\n\t\titems {\n\t\t\tid\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t\tid\n\t\t\t\t... on Event {\n\t\t\t\t\thappeningFrom\n\t\t\t\t\thappeningUntil\n\t\t\t\t}\n\t\t\t}\n\t\t\t...Event\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment TicketList on Order {\n\t\treference\n\t\titems {\n\t\t\tid\n\t\t\tproduct {\n\t\t\t\t__typename\n\t\t\t\tid\n\t\t\t\t... on Event {\n\t\t\t\t\thappeningFrom\n\t\t\t\t\thappeningUntil\n\t\t\t\t}\n\t\t\t}\n\t\t\t...Event\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CompareTicketLocation on CartItem {\n\t\tseat {\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t\trow\n\t\t\tname\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment CompareTicketLocation on CartItem {\n\t\tseat {\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t\trow\n\t\t\tname\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment Event on CartItem {\n\t\tid\n\t\tproduct {\n\t\t\t...EventHeader\n\t\t}\n\t\t...TicketListItem\n\t\t...CompareTicketLocation\n\t\t...TicketCheckboxes\n\t}\n"
): (typeof documents)["\n\tfragment Event on CartItem {\n\t\tid\n\t\tproduct {\n\t\t\t...EventHeader\n\t\t}\n\t\t...TicketListItem\n\t\t...CompareTicketLocation\n\t\t...TicketCheckboxes\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment EventHeader on Event {\n\t\tartist\n\t\thappeningFrom\n\t\tname\n\t\tslug\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t}\n\t\t\tname\n\t\t}\n\t\timage {\n\t\t\t...KSImage\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment EventHeader on Event {\n\t\tartist\n\t\thappeningFrom\n\t\tname\n\t\tslug\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t}\n\t\t\tname\n\t\t}\n\t\timage {\n\t\t\t...KSImage\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment TicketListItem on CartItem {\n\t\tid\n\t\tinvalidated\n\t\t...TicketLocationText\n\t\t...ItemDescription\n\t\tproduct {\n\t\t\tslug\n\t\t\t__typename\n\t\t\t... on Event {\n\t\t\t\tartist\n\t\t\t\tdescriptiveName\n\t\t\t\tvenue {\n\t\t\t\t\taddress {\n\t\t\t\t\t\tcity\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\thappeningFrom\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tphysical\n\t\t}\n\t\tticketUrl\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment TicketListItem on CartItem {\n\t\tid\n\t\tinvalidated\n\t\t...TicketLocationText\n\t\t...ItemDescription\n\t\tproduct {\n\t\t\tslug\n\t\t\t__typename\n\t\t\t... on Event {\n\t\t\t\tartist\n\t\t\t\tdescriptiveName\n\t\t\t\tvenue {\n\t\t\t\t\taddress {\n\t\t\t\t\t\tcity\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\thappeningFrom\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tphysical\n\t\t}\n\t\tticketUrl\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ItemDescription on CartItem {\n\t\tseat {\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t\trow\n\t\t\tname\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t\tvalidationCode\n\t\tinvalidated\n\t\tresaleOffer {\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t\t...TicketLocationText\n\t}\n"
): (typeof documents)["\n\tfragment ItemDescription on CartItem {\n\t\tseat {\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t\trow\n\t\t\tname\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t\tvalidationCode\n\t\tinvalidated\n\t\tresaleOffer {\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t\t...TicketLocationText\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery AccountPage {\n\t\tsession {\n\t\t\tuser {\n\t\t\t\temail\n\t\t\t\tfirstName\n\t\t\t\tid\n\t\t\t\tlastName\n\t\t\t}\n\t\t\tvalidUntil\n\t\t}\n\t\torders {\n\t\t\tnodes {\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\t...OrderList\n\t\t\t\tstatus\n\t\t\t\t...TicketList\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery AccountPage {\n\t\tsession {\n\t\t\tuser {\n\t\t\t\temail\n\t\t\t\tfirstName\n\t\t\t\tid\n\t\t\t\tlastName\n\t\t\t}\n\t\t\tvalidUntil\n\t\t}\n\t\torders {\n\t\t\tnodes {\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\t...OrderList\n\t\t\t\tstatus\n\t\t\t\t...TicketList\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CancelResale($input: CancelResaleInput!) {\n\t\tcancelResale(input: $input) {\n\t\t\tclientMutationId\n\t\t\titems {\n\t\t\t\t...FieldsUpdatedByMutation\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation CancelResale($input: CancelResaleInput!) {\n\t\tcancelResale(input: $input) {\n\t\t\tclientMutationId\n\t\t\titems {\n\t\t\t\t...FieldsUpdatedByMutation\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CreateResale($input: OfferForResaleInput!) {\n\t\tofferForResale(input: $input) {\n\t\t\tclientMutationId\n\t\t\titems {\n\t\t\t\t...FieldsUpdatedByMutation\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation CreateResale($input: OfferForResaleInput!) {\n\t\tofferForResale(input: $input) {\n\t\t\tclientMutationId\n\t\t\titems {\n\t\t\t\t...FieldsUpdatedByMutation\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment FieldsUpdatedByMutation on CartItem {\n\t\tid\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tcode\n\t\t\tid\n\t\t\tprice\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment FieldsUpdatedByMutation on CartItem {\n\t\tid\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tcode\n\t\t\tid\n\t\t\tprice\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment TicketCheckboxes on CartItem {\n\t\tid\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tcode\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\t...ItemDescription\n\t}\n"
): (typeof documents)["\n\tfragment TicketCheckboxes on CartItem {\n\t\tid\n\t\tresaleAvailable\n\t\tresaleOffer {\n\t\t\tcode\n\t\t\tpublic\n\t\t\tbought\n\t\t}\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\t...ItemDescription\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation FinishLogin($code: String!, $email: String!) {\n\t\tfinishCodeLogin(input: { code: $code, email: $email }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation FinishLogin($code: String!, $email: String!) {\n\t\tfinishCodeLogin(input: { code: $code, email: $email }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation RequestLogin($email: String!, $redirectUrl: String!) {\n\t\trequestCodeLogin(input: { email: $email, redirectUrl: $redirectUrl }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation RequestLogin($email: String!, $redirectUrl: String!) {\n\t\trequestCodeLogin(input: { email: $email, redirectUrl: $redirectUrl }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\t\tquery OrderPageMetadata($id: ID!) {\n\t\t\t\torder(id: $id) {\n\t\t\t\t\treference\n\t\t\t\t}\n\t\t\t}\n\t\t"
): (typeof documents)["\n\t\t\tquery OrderPageMetadata($id: ID!) {\n\t\t\t\torder(id: $id) {\n\t\t\t\t\treference\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\t\tquery OrderPage($id: ID!) {\n\t\t\t\torder(id: $id) {\n\t\t\t\t\titems {\n\t\t\t\t\t\tid\n\t\t\t\t\t}\n\t\t\t\t\t...OrderList\n\t\t\t\t\tstatus\n\t\t\t\t\t...TicketList\n\t\t\t\t}\n\t\t\t}\n\t\t"
): (typeof documents)["\n\t\t\tquery OrderPage($id: ID!) {\n\t\t\t\torder(id: $id) {\n\t\t\t\t\titems {\n\t\t\t\t\t\tid\n\t\t\t\t\t}\n\t\t\t\t\t...OrderList\n\t\t\t\t\tstatus\n\t\t\t\t\t...TicketList\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\tquery BandshopLayoutMetadata {\n\t\t\tshop {\n\t\t\t\tmeta\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t"
): (typeof documents)["\n\t\tquery BandshopLayoutMetadata {\n\t\t\tshop {\n\t\t\t\tmeta\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery BandshopPage {\n\t\tcategories {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tmainCategory\n\t\t\t\tslug\n\t\t\t\t__typename\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery BandshopPage {\n\t\tcategories {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tmainCategory\n\t\t\t\tslug\n\t\t\t\t__typename\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation ConfirmOrderPayment($orderId: ID!) {\n\t\tconfirmOrderPayment(input: { orderId: $orderId }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation ConfirmOrderPayment($orderId: ID!) {\n\t\tconfirmOrderPayment(input: { orderId: $orderId }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery Payment($ids: [ID!]!, $countryCode: String!) {\n\t\tpaymentMethods(ids: $ids, countryCode: $countryCode) {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery Payment($ids: [ID!]!, $countryCode: String!) {\n\t\tpaymentMethods(ids: $ids, countryCode: $countryCode) {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery Shipping($ids: [ID!]!, $countryCode: String!) {\n\t\tshippingMethods(ids: $ids, countryCode: $countryCode) {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tprice\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery Shipping($ids: [ID!]!, $countryCode: String!) {\n\t\tshippingMethods(ids: $ids, countryCode: $countryCode) {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tprice\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment NoteList on CartItem {\n\t\tproduct {\n\t\t\t__typename\n\t\t\tpublicShippableFrom\n\t\t\t... on Event {\n\t\t\t\thappeningFrom\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tphysical\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment NoteList on CartItem {\n\t\tproduct {\n\t\t\t__typename\n\t\t\tpublicShippableFrom\n\t\t\t... on Event {\n\t\t\t\thappeningFrom\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tphysical\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation PlaceOrder($input: PlaceOrderInput!) {\n\t\tplaceOrder(input: $input) {\n\t\t\tcart {\n\t\t\t\tid\n\t\t\t\treference\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation PlaceOrder($input: PlaceOrderInput!) {\n\t\tplaceOrder(input: $input) {\n\t\t\tcart {\n\t\t\t\tid\n\t\t\t\treference\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery EventDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t...EventMeta\n\t\t\t__typename\n\t\t\tartist\n\t\t\tavailable\n\t\t\tcartLimit\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\tdisplayMap\n\t\t\tdoorTime\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tid\n\t\t\timage {\n\t\t\t\turlPreview: url(width: 1024)\n\t\t\t\t...KSImage\n\t\t\t}\n\t\t\tkulturpassUrl\n\t\t\tlocalOrganizer\n\t\t\tmeta\n\t\t\tname\n\t\t\toverlayDetailImage {\n\t\t\t\t...KSImage\n\t\t\t}\n\t\t\towningCompanyAddress {\n\t\t\t\t...FormattedAddress\n\t\t\t}\n\t\t\tpresaleAt\n\t\t\tprice\n\t\t\tseatmapUrl\n\t\t\tsoldOutMessage\n\t\t\tstageTime\n\t\t\ttourOrganizer\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t}\n\t\t\ttours(first: 1) {\n\t\t\t\tnodes {\n\t\t\t\t\tslug\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery EventDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t...EventMeta\n\t\t\t__typename\n\t\t\tartist\n\t\t\tavailable\n\t\t\tcartLimit\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\tdisplayMap\n\t\t\tdoorTime\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tid\n\t\t\timage {\n\t\t\t\turlPreview: url(width: 1024)\n\t\t\t\t...KSImage\n\t\t\t}\n\t\t\tkulturpassUrl\n\t\t\tlocalOrganizer\n\t\t\tmeta\n\t\t\tname\n\t\t\toverlayDetailImage {\n\t\t\t\t...KSImage\n\t\t\t}\n\t\t\towningCompanyAddress {\n\t\t\t\t...FormattedAddress\n\t\t\t}\n\t\t\tpresaleAt\n\t\t\tprice\n\t\t\tseatmapUrl\n\t\t\tsoldOutMessage\n\t\t\tstageTime\n\t\t\ttourOrganizer\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t}\n\t\t\ttours(first: 1) {\n\t\t\t\tnodes {\n\t\t\t\t\tslug\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery EventMetaDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tstageTime\n\t\t\tslug\n\t\t\timage {\n\t\t\t\turlPreview: url(width: 1024)\n\t\t\t}\n\t\t\tname\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery EventMetaDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tstageTime\n\t\t\tslug\n\t\t\timage {\n\t\t\t\turlPreview: url(width: 1024)\n\t\t\t}\n\t\t\tname\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery EventCalendarDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tname\n\t\t\tdoorTime\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tstageTime\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery EventCalendarDetails($eventId: ID!) {\n\t\tevent(id: $eventId) {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tname\n\t\t\tdoorTime\n\t\t\thappeningFrom\n\t\t\thappeningUntil\n\t\t\tstageTime\n\t\t\tvenue {\n\t\t\t\tname\n\t\t\t\taddress {\n\t\t\t\t\tcity\n\t\t\t\t\t...FormattedAddress\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment EventVariant on EventProductVariant {\n\t\t__typename\n\t\tactive\n\t\tavailable\n\t\tdescription\n\t\tid\n\t\tphysical\n\t\tprice\n\t\tpricingCategory {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tavailableSections {\n\t\t\tpageInfo {\n\t\t\t\tendCursor\n\t\t\t\thasNextPage\n\t\t\t}\n\t\t\tnodes {\n\t\t\t\t...EventVariantSection\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment EventVariant on EventProductVariant {\n\t\t__typename\n\t\tactive\n\t\tavailable\n\t\tdescription\n\t\tid\n\t\tphysical\n\t\tprice\n\t\tpricingCategory {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tavailableSections {\n\t\t\tpageInfo {\n\t\t\t\tendCursor\n\t\t\t\thasNextPage\n\t\t\t}\n\t\t\tnodes {\n\t\t\t\t...EventVariantSection\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery EventVariantsPage($eventId: ID!, $after: String) {\n\t\tevent(id: $eventId) {\n\t\t\tproductVariants(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t__typename\n\t\t\t\t\t... on EventProductVariant {\n\t\t\t\t\t\t...EventVariant\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\thasNextPage\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery EventVariantsPage($eventId: ID!, $after: String) {\n\t\tevent(id: $eventId) {\n\t\t\tproductVariants(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t__typename\n\t\t\t\t\t... on EventProductVariant {\n\t\t\t\t\t\t...EventVariant\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\thasNextPage\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment EventVariantSection on VenueSection {\n\t\tid\n\t\tname\n\t}\n"
): (typeof documents)["\n\tfragment EventVariantSection on VenueSection {\n\t\tid\n\t\tname\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery EventVariantSectionPage($eventId: ID!, $variantId: ID!, $after: String) {\n\t\tevent(id: $eventId) {\n\t\t\tproductVariant(id: $variantId) {\n\t\t\t\t__typename\n\t\t\t\t... on EventProductVariant {\n\t\t\t\t\tavailableSections(after: $after) {\n\t\t\t\t\t\tpageInfo {\n\t\t\t\t\t\t\tendCursor\n\t\t\t\t\t\t\thasNextPage\n\t\t\t\t\t\t}\n\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t...EventVariantSection\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery EventVariantSectionPage($eventId: ID!, $variantId: ID!, $after: String) {\n\t\tevent(id: $eventId) {\n\t\t\tproductVariant(id: $variantId) {\n\t\t\t\t__typename\n\t\t\t\t... on EventProductVariant {\n\t\t\t\t\tavailableSections(after: $after) {\n\t\t\t\t\t\tpageInfo {\n\t\t\t\t\t\t\tendCursor\n\t\t\t\t\t\t\thasNextPage\n\t\t\t\t\t\t}\n\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t...EventVariantSection\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery ProductDetails($productId: ID!) {\n\t\tproduct(id: $productId) {\n\t\t\t...ProductMeta\n\t\t\t__typename\n\t\t\tartist\n\t\t\tavailable\n\t\t\tcartLimit\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\tid\n\t\t\tslug\n\t\t\tmeta\n\t\t\tname\n\t\t\tprice(countryCode: "de")\n\t\t\tpublicShippableFrom\n\t\t\tshippingRequirements\n\t\t\t... on MerchProduct {\n\t\t\t\tmaterial\n\t\t\t\tadditionalInformation {\n\t\t\t\t\tdescription\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t\tcolor\n\t\t\t\tdetails {\n\t\t\t\t\tdescription\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t\tlowStock\n\t\t\t\toriginalPrice(countryCode: "de")\n\t\t\t\tproductType\n\t\t\t}\n\t\t}\n\t}\n'
): (typeof documents)['\n\tquery ProductDetails($productId: ID!) {\n\t\tproduct(id: $productId) {\n\t\t\t...ProductMeta\n\t\t\t__typename\n\t\t\tartist\n\t\t\tavailable\n\t\t\tcartLimit\n\t\t\tdescription\n\t\t\tdescriptiveName\n\t\t\tid\n\t\t\tslug\n\t\t\tmeta\n\t\t\tname\n\t\t\tprice(countryCode: "de")\n\t\t\tpublicShippableFrom\n\t\t\tshippingRequirements\n\t\t\t... on MerchProduct {\n\t\t\t\tmaterial\n\t\t\t\tadditionalInformation {\n\t\t\t\t\tdescription\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t\tcolor\n\t\t\t\tdetails {\n\t\t\t\t\tdescription\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t\tlowStock\n\t\t\t\toriginalPrice(countryCode: "de")\n\t\t\t\tproductType\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment ProductDetailVariant on ProductVariant {\n\t\tactive\n\t\tavailable\n\t\tid\n\t\tdescription\n\t\tproductId\n\t\torder\n\t\tprice(countryCode: "de")\n\t}\n'
): (typeof documents)['\n\tfragment ProductDetailVariant on ProductVariant {\n\t\tactive\n\t\tavailable\n\t\tid\n\t\tdescription\n\t\tproductId\n\t\torder\n\t\tprice(countryCode: "de")\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery ProductDetailVariantPage($productId: ID!, $after: String) {\n\t\tproduct(id: $productId) {\n\t\t\tid\n\t\t\tproductVariants(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t...ProductDetailVariant\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\thasNextPage\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery ProductDetailVariantPage($productId: ID!, $after: String) {\n\t\tproduct(id: $productId) {\n\t\t\tid\n\t\t\tproductVariants(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t...ProductDetailVariant\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\thasNextPage\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductDetailImage on Image {\n\t\t...KSImagesWithTags\n\t\ttags\n\t\turlPreview: url(width: 1024)\n\t}\n"
): (typeof documents)["\n\tfragment ProductDetailImage on Image {\n\t\t...KSImagesWithTags\n\t\ttags\n\t\turlPreview: url(width: 1024)\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery ProductDetailImagePage($productId: ID!, $after: String) {\n\t\tproduct(id: $productId) {\n\t\t\t__typename\n\t\t\t... on MerchProduct {\n\t\t\t\timages(after: $after) {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\t...ProductDetailImage\n\t\t\t\t\t}\n\t\t\t\t\tpageInfo {\n\t\t\t\t\t\thasNextPage\n\t\t\t\t\t\tendCursor\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery ProductDetailImagePage($productId: ID!, $after: String) {\n\t\tproduct(id: $productId) {\n\t\t\t__typename\n\t\t\t... on MerchProduct {\n\t\t\t\timages(after: $after) {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\t...ProductDetailImage\n\t\t\t\t\t}\n\t\t\t\t\tpageInfo {\n\t\t\t\t\t\thasNextPage\n\t\t\t\t\t\tendCursor\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery TourDetails($tourId: ID!) {\n\t\tcategory(id: $tourId) {\n\t\t\t__typename\n\t\t\tdescription\n\t\t\tid\n\t\t\tname\n\t\t\t... on Tour {\n\t\t\t\tticketImage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t\tsubtitle\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery TourDetails($tourId: ID!) {\n\t\tcategory(id: $tourId) {\n\t\t\t__typename\n\t\t\tdescription\n\t\t\tid\n\t\t\tname\n\t\t\t... on Tour {\n\t\t\t\tticketImage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t\tsubtitle\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment HomeNews on News {\n\t\tbgColor: url\n\t\timage {\n\t\t\t...KSImage\n\t\t}\n\t\ttext\n\t\ttitle\n\t}\n"
): (typeof documents)["\n\tfragment HomeNews on News {\n\t\tbgColor: url\n\t\timage {\n\t\t\t...KSImage\n\t\t}\n\t\ttext\n\t\ttitle\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment HomeArtists on CategoryInterface {\n\t\tname\n\t\tslug\n\t\tsquareImage {\n\t\t\t...KSImage\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment HomeArtists on CategoryInterface {\n\t\tname\n\t\tslug\n\t\tsquareImage {\n\t\t\t...KSImage\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\tquery KrasserstoffHomePage {\n\t\t\tcategories(types: ARTIST) {\n\t\t\t\tnodes {\n\t\t\t\t\t...HomeArtists\n\t\t\t\t\tmainCategory\n\t\t\t\t}\n\t\t\t}\n\t\t\tnews(first: 4) {\n\t\t\t\tnodes {\n\t\t\t\t\t...HomeNews\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"
): (typeof documents)["\n\t\tquery KrasserstoffHomePage {\n\t\t\tcategories(types: ARTIST) {\n\t\t\t\tnodes {\n\t\t\t\t\t...HomeArtists\n\t\t\t\t\tmainCategory\n\t\t\t\t}\n\t\t\t}\n\t\t\tnews(first: 4) {\n\t\t\t\tnodes {\n\t\t\t\t\t...HomeNews\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\t\tquery SeatmapMetadata($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tdescriptiveName\n\t\t\t\t}\n\t\t\t}\n\t\t"
): (typeof documents)["\n\t\t\tquery SeatmapMetadata($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tdescriptiveName\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\t\tquery SeatmapSvg($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tavailableSeatIds\n\t\t\t\t\tdescriptiveName\n\t\t\t\t\tproductVariants {\n\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t__typename\n\t\t\t\t\t\t\tavailable\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t... on EventProductVariant {\n\t\t\t\t\t\t\t\tavailableSectionIds\n\t\t\t\t\t\t\t\tpricingCategory {\n\t\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\tseatmapUrl\n\t\t\t\t}\n\t\t\t}\n\t\t"
): (typeof documents)["\n\t\t\tquery SeatmapSvg($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tavailableSeatIds\n\t\t\t\t\tdescriptiveName\n\t\t\t\t\tproductVariants {\n\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t__typename\n\t\t\t\t\t\t\tavailable\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t... on EventProductVariant {\n\t\t\t\t\t\t\t\tavailableSectionIds\n\t\t\t\t\t\t\t\tpricingCategory {\n\t\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\tseatmapUrl\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\t\tquery SeatmapPage($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tdescriptiveName\n\t\t\t\t\tdisplayMap\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t"
): (typeof documents)["\n\t\t\tquery SeatmapPage($eventSlug: ID!) {\n\t\t\t\tevent(id: $eventSlug) {\n\t\t\t\t\tdescriptiveName\n\t\t\t\t\tdisplayMap\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment FormattedAddress on Address {\n\t\taddress\n\t\taddress2\n\t\tcity\n\t\tcompany\n\t\tcountry {\n\t\t\tcountryCode\n\t\t\tname\n\t\t}\n\t\tfirstName\n\t\tlastName\n\t\tpostCode\n\t}\n"
): (typeof documents)["\n\tfragment FormattedAddress on Address {\n\t\taddress\n\t\taddress2\n\t\tcity\n\t\tcompany\n\t\tcountry {\n\t\t\tcountryCode\n\t\t\tname\n\t\t}\n\t\tfirstName\n\t\tlastName\n\t\tpostCode\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment TicketLocationText on CartItem {\n\t\tseat {\n\t\t\tname\n\t\t\trow\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment TicketLocationText on CartItem {\n\t\tseat {\n\t\t\tname\n\t\t\trow\n\t\t\tvenueSection {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t\tsection {\n\t\t\tname\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery BandshopNews {\n\t\tnews(categories: ["regular"], first: 5) {\n\t\t\tnodes {\n\t\t\t\turl\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\ttext\n\t\t\t\timage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n'
): (typeof documents)['\n\tquery BandshopNews {\n\t\tnews(categories: ["regular"], first: 5) {\n\t\t\tnodes {\n\t\t\t\turl\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\ttext\n\t\t\t\timage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery BandshopNewsTitle {\n\t\tnews(categories: ["hot"], first: 1) {\n\t\t\tnodes {\n\t\t\t\ttitle\n\t\t\t\ttext\n\t\t\t\turl\n\t\t\t}\n\t\t}\n\t}\n'
): (typeof documents)['\n\tquery BandshopNewsTitle {\n\t\tnews(categories: ["hot"], first: 1) {\n\t\t\tnodes {\n\t\t\t\ttitle\n\t\t\t\ttext\n\t\t\t\turl\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery CategoryCard($id: ID!, $after: String!) {\n\t\tcategory(id: $id) {\n\t\t\t__typename\n\t\t\tname\n\t\t\t... on Category {\n\t\t\t\tmerchProducts(after: $after, sortDirection: DESCENDING) {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\tid\n\t\t\t\t\t\t...ProductCard\n\t\t\t\t\t}\n\t\t\t\t\tpageInfo {\n\t\t\t\t\t\tendCursor\n\t\t\t\t\t\thasNextPage\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery CategoryCard($id: ID!, $after: String!) {\n\t\tcategory(id: $id) {\n\t\t\t__typename\n\t\t\tname\n\t\t\t... on Category {\n\t\t\t\tmerchProducts(after: $after, sortDirection: DESCENDING) {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\tid\n\t\t\t\t\t\t...ProductCard\n\t\t\t\t\t}\n\t\t\t\t\tpageInfo {\n\t\t\t\t\t\tendCursor\n\t\t\t\t\t\thasNextPage\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery CategoryEvents($tourId: ID!, $after: String!) {\n\t\tcategory(id: $tourId) {\n\t\t\tevents(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t...EventListEvent\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\tendCursor\n\t\t\t\t\thasNextPage\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery CategoryEvents($tourId: ID!, $after: String!) {\n\t\tcategory(id: $tourId) {\n\t\t\tevents(after: $after) {\n\t\t\t\tnodes {\n\t\t\t\t\t...EventListEvent\n\t\t\t\t}\n\t\t\t\tpageInfo {\n\t\t\t\t\tendCursor\n\t\t\t\t\thasNextPage\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery EventListDetails($tourId: ID!) {\n\t\tcategory(id: $tourId) {\n\t\t\t__typename\n\t\t\tid\n\t\t\tname\n\t\t\t... on Tour {\n\t\t\t\tticketImage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t\tsubtitle\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery EventListDetails($tourId: ID!) {\n\t\tcategory(id: $tourId) {\n\t\t\t__typename\n\t\t\tid\n\t\t\tname\n\t\t\t... on Tour {\n\t\t\t\tticketImage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t}\n\t\t\tsubtitle\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment EventListEvent on Event {\n\t\t...EventMeta\n\t\tartist\n\t\tavailable\n\t\thappeningFrom\n\t\thappeningUntil\n\t\tid\n\t\tmeta\n\t\tname\n\t\tpresaleAt\n\t\tprice\n\t\tproductVariants {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tprice\n\t\t\t}\n\t\t}\n\t\tslug\n\t\tsoldOutMessage\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t}\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment EventListEvent on Event {\n\t\t...EventMeta\n\t\tartist\n\t\tavailable\n\t\thappeningFrom\n\t\thappeningUntil\n\t\tid\n\t\tmeta\n\t\tname\n\t\tpresaleAt\n\t\tprice\n\t\tproductVariants {\n\t\t\tnodes {\n\t\t\t\tid\n\t\t\t\tprice\n\t\t\t}\n\t\t}\n\t\tslug\n\t\tsoldOutMessage\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t}\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\tquery BandshopFilter {\n\t\t\tcategories {\n\t\t\t\tnodes {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tslug\n\t\t\t\t\tmainCategory\n\t\t\t\t\t__typename\n\t\t\t\t\t... on Category {\n\t\t\t\t\t\t# query first product to check if category has products\n\t\t\t\t\t\tmerchProducts(first: 1) {\n\t\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\t... on Tour {\n\t\t\t\t\t\t# query first event to check if category has events\n\t\t\t\t\t\tevents(first: 1) {\n\t\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"
): (typeof documents)["\n\t\tquery BandshopFilter {\n\t\t\tcategories {\n\t\t\t\tnodes {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tslug\n\t\t\t\t\tmainCategory\n\t\t\t\t\t__typename\n\t\t\t\t\t... on Category {\n\t\t\t\t\t\t# query first product to check if category has products\n\t\t\t\t\t\tmerchProducts(first: 1) {\n\t\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\t... on Tour {\n\t\t\t\t\t\t# query first event to check if category has events\n\t\t\t\t\t\tevents(first: 1) {\n\t\t\t\t\t\t\tnodes {\n\t\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment KSImage on Image {\n\t\talt\n\t\theight\n\t\tthumbhash\n\t\turl16: url(width: 16)\n\t\turl32: url(width: 32)\n\t\turl48: url(width: 48)\n\t\turl64: url(width: 64)\n\t\turl96: url(width: 96)\n\t\t# rule doesn't support natural sorting\n\t\t# eslint-disable-next-line @graphql-eslint/alphabetize\n\t\turl128: url(width: 128)\n\t\turl256: url(width: 256)\n\t\turl384: url(width: 384)\n\t\turl390: url(width: 390)\n\t\turl485: url(width: 485)\n\t\turl834: url(width: 834)\n\t\t# eslint-disable-next-line @graphql-eslint/alphabetize\n\t\turl1024: url(width: 1024)\n\t\turl1280: url(width: 1280)\n\t\turl1440: url(width: 1440)\n\t\turl1920: url(width: 1920)\n\t\turl2560: url(width: 2560)\n\t\turl3440: url(width: 3440)\n\t\twidth\n\t}\n"
): (typeof documents)["\n\tfragment KSImage on Image {\n\t\talt\n\t\theight\n\t\tthumbhash\n\t\turl16: url(width: 16)\n\t\turl32: url(width: 32)\n\t\turl48: url(width: 48)\n\t\turl64: url(width: 64)\n\t\turl96: url(width: 96)\n\t\t# rule doesn't support natural sorting\n\t\t# eslint-disable-next-line @graphql-eslint/alphabetize\n\t\turl128: url(width: 128)\n\t\turl256: url(width: 256)\n\t\turl384: url(width: 384)\n\t\turl390: url(width: 390)\n\t\turl485: url(width: 485)\n\t\turl834: url(width: 834)\n\t\t# eslint-disable-next-line @graphql-eslint/alphabetize\n\t\turl1024: url(width: 1024)\n\t\turl1280: url(width: 1280)\n\t\turl1440: url(width: 1440)\n\t\turl1920: url(width: 1920)\n\t\turl2560: url(width: 2560)\n\t\turl3440: url(width: 3440)\n\t\twidth\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment ProductCard on MerchProduct {\n\t\t...ProductMeta\n\t\tartist\n\t\tid\n\t\timages {\n\t\t\tnodes {\n\t\t\t\t...KSImagesWithTags\n\t\t\t}\n\t\t}\n\t\tname\n\t\tmeta\n\t\toriginalPrice(countryCode: "de")\n\t\tprice(countryCode: "de")\n\t\tproductType\n\t\tcolor\n\t\tslug\n\t}\n'
): (typeof documents)['\n\tfragment ProductCard on MerchProduct {\n\t\t...ProductMeta\n\t\tartist\n\t\tid\n\t\timages {\n\t\t\tnodes {\n\t\t\t\t...KSImagesWithTags\n\t\t\t}\n\t\t}\n\t\tname\n\t\tmeta\n\t\toriginalPrice(countryCode: "de")\n\t\tprice(countryCode: "de")\n\t\tproductType\n\t\tcolor\n\t\tslug\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CartItemList on CartItem {\n\t\t...CartItem\n\t\tbundledItemIds\n\t\tid\n\t\tinvalidated\n\t\tseatId\n\t\tproduct {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tdescriptiveName\n\t\t\tid\n\t\t\tmeta\n\t\t\tname\n\t\t\t... on MerchProduct {\n\t\t\t\tmaterial\n\t\t\t\tcolor\n\t\t\t\timages {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\t...KSImagesWithTags\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tproductType\n\t\t\t}\n\t\t\t... on Event {\n\t\t\t\thappeningFrom\n\t\t\t\timage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t\tvenue {\n\t\t\t\t\taddress {\n\t\t\t\t\t\tcity\n\t\t\t\t\t}\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tdescription\n\t\t\tid\n\t\t\tphysical\n\t\t}\n\t\t...TicketLocationText\n\t\ttotal\n\t}\n"
): (typeof documents)["\n\tfragment CartItemList on CartItem {\n\t\t...CartItem\n\t\tbundledItemIds\n\t\tid\n\t\tinvalidated\n\t\tseatId\n\t\tproduct {\n\t\t\t__typename\n\t\t\tartist\n\t\t\tdescriptiveName\n\t\t\tid\n\t\t\tmeta\n\t\t\tname\n\t\t\t... on MerchProduct {\n\t\t\t\tmaterial\n\t\t\t\tcolor\n\t\t\t\timages {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\t...KSImagesWithTags\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tproductType\n\t\t\t}\n\t\t\t... on Event {\n\t\t\t\thappeningFrom\n\t\t\t\timage {\n\t\t\t\t\t...KSImage\n\t\t\t\t}\n\t\t\t\tvenue {\n\t\t\t\t\taddress {\n\t\t\t\t\t\tcity\n\t\t\t\t\t}\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tproductVariant {\n\t\t\tdescription\n\t\t\tid\n\t\t\tphysical\n\t\t}\n\t\t...TicketLocationText\n\t\ttotal\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CartItem on CartItem {\n\t\tid\n\t\tinvalidated\n\t\tproduct {\n\t\t\tslug\n\t\t\tartist\n\t\t\tcartLimit\n\t\t\tdescriptiveName\n\t\t\tpublicShippableFrom\n\t\t\t__typename\n\t\t}\n\t\tproductVariant {\n\t\t\tid\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment CartItem on CartItem {\n\t\tid\n\t\tinvalidated\n\t\tproduct {\n\t\t\tslug\n\t\t\tartist\n\t\t\tcartLimit\n\t\t\tdescriptiveName\n\t\t\tpublicShippableFrom\n\t\t\t__typename\n\t\t}\n\t\tproductVariant {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment EventMeta on Event {\n\t\tartist\n\t\tavailable\n\t\thappeningFrom\n\t\thappeningUntil\n\t\tmeta\n\t\tname\n\t\tpresaleAt\n\t\tprice\n\t\tstageTime\n\t\tdoorTime\n\t\tdescription\n\t\tlocalOrganizer\n\t\tslug\n\t\tsoldOutMessage\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\taddress\n\t\t\t\tpostCode\n\t\t\t}\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\timage {\n\t\t\turlPreview: url(width: 1024)\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment EventMeta on Event {\n\t\tartist\n\t\tavailable\n\t\thappeningFrom\n\t\thappeningUntil\n\t\tmeta\n\t\tname\n\t\tpresaleAt\n\t\tprice\n\t\tstageTime\n\t\tdoorTime\n\t\tdescription\n\t\tlocalOrganizer\n\t\tslug\n\t\tsoldOutMessage\n\t\tvenue {\n\t\t\taddress {\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\taddress\n\t\t\t\tpostCode\n\t\t\t}\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\timage {\n\t\t\turlPreview: url(width: 1024)\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment ProductMeta on MerchProduct {\n\t\tartist\n\t\tid\n\t\timages {\n\t\t\tnodes {\n\t\t\t\t...KSImagesWithTags\n\t\t\t}\n\t\t}\n\t\tname\n\t\tmeta\n\t\toriginalPrice(countryCode: "de")\n\t\tprice(countryCode: "de")\n\t\tproductType\n\t\tcolor\n\t\tslug\n\t\tmaterial\n\t\tpublicShippableFrom\n\t\tlowStock\n\t\tavailable\n\t}\n'
): (typeof documents)['\n\tfragment ProductMeta on MerchProduct {\n\t\tartist\n\t\tid\n\t\timages {\n\t\t\tnodes {\n\t\t\t\t...KSImagesWithTags\n\t\t\t}\n\t\t}\n\t\tname\n\t\tmeta\n\t\toriginalPrice(countryCode: "de")\n\t\tprice(countryCode: "de")\n\t\tproductType\n\t\tcolor\n\t\tslug\n\t\tmaterial\n\t\tpublicShippableFrom\n\t\tlowStock\n\t\tavailable\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery SessionExpiresAt {\n\t\tsession {\n\t\t\tid\n\t\t\texpiresAt\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery SessionExpiresAt {\n\t\tsession {\n\t\t\tid\n\t\t\texpiresAt\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation Heartbeat {\n\t\theartbeat(input: {}) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation Heartbeat {\n\t\theartbeat(input: {}) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment UseCartCart on Cart {\n\t\tid # id is needed for apollo to keep track of the cart object, don't remove it!\n\t\tcountryCode\n\t\treference\n\t\titems {\n\t\t\tproductVariant {\n\t\t\t\tphysical\n\t\t\t}\n\t\t\tproductVariantId\n\t\t\t...CartItemList\n\t\t\t...NoteList\n\t\t}\n\t\ttotal\n\t\t...UseDeliveryCountry\n\t}\n"
): (typeof documents)["\n\tfragment UseCartCart on Cart {\n\t\tid # id is needed for apollo to keep track of the cart object, don't remove it!\n\t\tcountryCode\n\t\treference\n\t\titems {\n\t\t\tproductVariant {\n\t\t\t\tphysical\n\t\t\t}\n\t\t\tproductVariantId\n\t\t\t...CartItemList\n\t\t\t...NoteList\n\t\t}\n\t\ttotal\n\t\t...UseDeliveryCountry\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment UseDeliveryCountry on Cart {\n\t\tcountryCode\n\t}\n"
): (typeof documents)["\n\tfragment UseDeliveryCountry on Cart {\n\t\tcountryCode\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery UseCart {\n\t\tcart {\n\t\t\t...UseCartCart\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery UseCart {\n\t\tcart {\n\t\t\t...UseCartCart\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation RemoveItem($itemId: ID!) {\n\t\tremoveItem(input: { itemId: $itemId }) {\n\t\t\tcart {\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation RemoveItem($itemId: ID!) {\n\t\tremoveItem(input: { itemId: $itemId }) {\n\t\t\tcart {\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation LogActivity($action: String!, $details: JSON!) {\n\t\tlogActivity(input: { action: $action, details: $details }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation LogActivity($action: String!, $details: JSON!) {\n\t\tlogActivity(input: { action: $action, details: $details }) {\n\t\t\tclientMutationId\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation AddItems($addItemRequests: [Request!]!) {\n\t\t# (input: {requests: [{productVariantId: "c729274f-f6f6-47b0-9789-f1b5c03dd220", amount: 1}]})\n\t\taddItems(input: { requests: $addItemRequests }) {\n\t\t\tcart {\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n'
): (typeof documents)['\n\tmutation AddItems($addItemRequests: [Request!]!) {\n\t\t# (input: {requests: [{productVariantId: "c729274f-f6f6-47b0-9789-f1b5c03dd220", amount: 1}]})\n\t\taddItems(input: { requests: $addItemRequests }) {\n\t\t\tcart {\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation ChangeCountry($countryCode: ID!) {\n\t\tchangeCountry(input: { countryCode: $countryCode }) {\n\t\t\tcart {\n\t\t\t\t# we need to refetch the whole cart because the prices can change when the country changes\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation ChangeCountry($countryCode: ID!) {\n\t\tchangeCountry(input: { countryCode: $countryCode }) {\n\t\t\tcart {\n\t\t\t\t# we need to refetch the whole cart because the prices can change when the country changes\n\t\t\t\t...UseCartCart\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment KSImagesWithTags on Image {\n\t\t...KSImage\n\t\torder\n\t\ttags\n\t\turlPreview: url(width: 1024)\n\t}\n"
): (typeof documents)["\n\tfragment KSImagesWithTags on Image {\n\t\t...KSImage\n\t\torder\n\t\ttags\n\t\turlPreview: url(width: 1024)\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t\tquery ShopMeta {\n\t\t\tshop {\n\t\t\t\tmeta\n\t\t\t}\n\t\t}\n\t"
): (typeof documents)["\n\t\tquery ShopMeta {\n\t\t\tshop {\n\t\t\t\tmeta\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery Bandshop {\n\t\tshop {\n\t\t\tdefaultLocale\n\t\t\tdomain\n\t\t\tslug\n\t\t\tname\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery Bandshop {\n\t\tshop {\n\t\t\tdefaultLocale\n\t\t\tdomain\n\t\t\tslug\n\t\t\tname\n\t\t}\n\t}\n"];

export function graphql(source: string) {
	return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
	TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
